import NavigationBar from 'components/NavigationBar';
import { Route, useHistory } from 'react-router-dom';

import DashboardPage from 'pages/dashboardPage/DashboardPage';
import { useAuthState } from 'stores/AuthStore';

// Styling
import styled, { StyledProps } from 'styled-components';
import DataLoader from 'stores/DataLoader';
import UsersPage from 'pages/usersPage/UsersPage';
import ShowcasesPage from 'pages/showcasesPage/ShowcasesPage';
import ProjectDetailPage from 'pages/projectDetailPage/ProjectDetailPage';
import MarketplacePage from 'pages/marketplacePage/MarketplacePage';
import LeadsReportingPage from 'pages/leadsReportingPage/LeadsReportingPage';

const FullscreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

const RouteWrapper = styled.div<StyledProps<{ pageScrollEnabled: boolean }>>`
  width: 100%;
  height: calc(100vh - 60px);
  ${({ pageScrollEnabled }) => (pageScrollEnabled ? '' : 'overflow: hidden')};
`;

const LandingPage = () => {
  const { AuthState } = useAuthState();
  const { sessionToken } = AuthState;

  const history = useHistory();

  if (!sessionToken) {
    history.push('/login');
  }

  return (
    <FullscreenWrapper>
      <NavigationBar />
      <DataLoader />
      <RouteWrapper pageScrollEnabled={true}>
        <Route exact path="/marketplace" component={MarketplacePage} />
        <Route exact path="/reporting" component={LeadsReportingPage} />
        <Route exact path="/overview" component={DashboardPage} />
        <Route exact path="/users" component={UsersPage} />
        <Route exact path="/showcases" component={ShowcasesPage} />
        <Route exact path="/project/:projectid" component={ProjectDetailPage} />
      </RouteWrapper>
    </FullscreenWrapper>
  );
};

export default LandingPage;
