import React from 'react';
import { Route } from 'react-router-dom';
import LoginPage from 'pages/login/LoginPage';
import LandingPage from 'pages/landingPage/LandingPage';

export const Routes = () => {
  return (
    <>
      <Route exact path="/" component={LoginPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route
        exact
        path={[
          '/overview',
          '/users',
          '/showcases',
          '/project/:projectid',
          '/marketplace',
          '/reporting'
        ]}
        component={LandingPage}
      />
      {/* <Route path="*" component={The404Component} /> */}
    </>
  );
};

export default Routes;
