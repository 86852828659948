import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DataStore from "./stores/DataStore";
import AuthStore from "./stores/AuthStore";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const storedSessionToken = cookies.get("sessionToken");
const storedStorageToken = cookies.get("storageToken");

ReactDOM.render(
  <BrowserRouter>
    <AuthStore
      storedSessionToken={storedSessionToken}
      storedStorageToken={storedStorageToken}
    >
      <DataStore>
        <App />
      </DataStore>
    </AuthStore>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
