export const buildLabelsAndTimestampsForLast30Days = () => {
  const startOfDay = new Date();
  startOfDay.setUTCHours(0, 0, 0, 0);

  let currentDay = startOfDay;

  let labelsAndTimestamps = [];
  for (let i = 0; i < 30; i++) {
    const day = currentDay.getDate();
    const month = currentDay.getMonth() + 1;

    labelsAndTimestamps.push({
      label: `${day}/${month}`,
      startTimestamp: currentDay.getTime(),
      endTimestamp: currentDay.getTime() + 86400000,
      isWeekend: currentDay.getDay() === 6 || currentDay.getDay() === 0,
    });

    currentDay = new Date(currentDay.getTime() - 86400000);
  }

  return labelsAndTimestamps;
};
