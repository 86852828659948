// Styling
import styled from "styled-components";
import ShowcaseList from "./components/ShowcaseList";

const SPACING = 30;

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 60px);
  background: ${({ theme }) => theme.background100};
  padding-left: ${SPACING * 1.5}px;
  padding-right: ${SPACING * 1.5}px;
  box-sizing: border-box;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-top: ${SPACING - 10}px;
  height: 100%;
`;

const ShowcasesPage = () => {
  return (
    <PageWrapper>
      <HorizontalWrapper>
        <ShowcaseList />
      </HorizontalWrapper>
    </PageWrapper>
  );
};

export default ShowcasesPage;
