import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Select, { components } from "react-select";

// Styling
import styled, { withTheme } from "styled-components";
import { clamp } from "helpers";

export interface PaginationControlsProps {
  perPage: any;
  currentPage: number;
  totalEntries: number;
  toPreviousPage: any;
  changePerPage: any;
  toNextPage: any;
  theme: any;
}

const Label = styled.div`
  margin-right: 8px;
  color: ${(props) => props.theme.fadedTextColor};
`;

const CurrentPageIndicator = styled.div`
  margin-left: 32px;
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 60px;
  background: ${(props) => props.theme.mainBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  user-select: none;
  font-family: SFProText-Regular;
`;

const PreviousButton = styled.div`
  margin-left: 16px;
  color: ${(props) => props.theme.fadedTextColor};
  cursor: pointer;
`;

const NextButton = styled.div`
  margin-left: 32px;
  color: ${(props) => props.theme.fadedTextColor};
  cursor: pointer;
`;

const NextIcon = styled(FontAwesomeIcon)``;

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <NextIcon icon={solid("caret-down")} />
    </components.DropdownIndicator>
  );
};

const PaginationControls = ({
  perPage,
  currentPage,
  totalEntries,
  changePerPage,
  toPreviousPage,
  theme,
  toNextPage,
}: PaginationControlsProps) => {
  const perPageOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 200, value: 200 },
  ];
  const customStyles = {
    control: (provided: any, state: any) => {
      return {
        ...provided,
        borderRadius: 0,
        border: "none",
        background: "transparent",
        outline: "transparent",
        boxShadow: "transparent",
        flexWrap: "nowrap",
        width: "75px",
        fontSize: "1rem",
        margin: "4px 0",
        cursor: "pointer",
      };
    },
    dropdownIndicator: (provided: any) => {
      return {
        ...provided,
        position: "absolute",
        left: "35px",
      };
    },
    indicatorSeparator: () => ({
      display: "none",
    }),
    menuList: () => ({
      overflow: "hidden",
      color: theme.mainTextColor,
      background: theme.mainBackgroundColor,
    }),
    singleValue: () => ({ color: theme.mainTextColor, position: "absolute" }),
    option: (provided: any, state: any) => {
      let background = state.isSelected
        ? theme.fadedBackgroundColor
        : "transparent";
      if (state.isFocused) {
        background = theme.accentColor;
      }

      return {
        ...provided,
        background,
        color: theme.mainTextColor,
        cursor: "pointer",
      };
    },
    input: () => ({
      isDisabled: true,
      isHidden: true,
      display: "none !important",
      height: "0px",
    }),
  };

  return (
    <MainWrapper>
      <Label>{"Per page"}: </Label>
      <Select
        value={perPage}
        //@ts-ignore
        styles={customStyles}
        options={perPageOptions}
        isSearchable={false}
        menuPlacement={"top"}
        placeholder={"10"}
        onChange={(e: any) => {
          changePerPage(e);
        }}
        components={{ DropdownIndicator }}
      />
      <CurrentPageIndicator>{`${totalEntries > 0 ? currentPage + 1 : 0}-${clamp(
        currentPage + perPage.value,
        1,
        totalEntries
      )} of ${totalEntries}`}</CurrentPageIndicator>
      <PreviousButton onClick={toPreviousPage}>{"<"}</PreviousButton>
      <NextButton onClick={toNextPage}>{">"}</NextButton>
    </MainWrapper>
  );
};

export default withTheme(PaginationControls);
