import { Marketplace } from 'helpers/neo4j/neo4jHelper';
import { Units } from 'helpers/neo4j/portal/units';
import { buildLabelsAndTimestampsForLast30Days } from 'pages/dashboardPage/helpers/DashboardHelper';

import { v4 as uuidv4 } from 'uuid';

export interface Job {
  jobId: string;
  params?: any;
  call: any;
  stateName: string;
  inProgress?: boolean;
}

export const getMarketplaceDataJobs = () => {
  let jobList = [];
  // // Get all the vaults
  // initialJobs.push({
  //   jobId: uuidv4(),
  //   call: getAllVaults(),
  //   stateName: "vaults",
  // });

  // // Get all the users
  // initialJobs.push({
  //   jobId: uuidv4(),
  //   call: getAllUsers(),
  //   stateName: "users",
  // });

  // Get all the projects
  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [],
  //   call: getAllProjects,
  //   stateName: "allProjects",
  // });

  // //Get the total count of units
  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [],
  //   call: getAllUnitsCount,
  //   stateName: "totalUnitAmount",
  // });

  // // Get the total count of active units
  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [],
  //   call: getAllActiveUnitsCount,
  //   stateName: "totalActiveUnitAmount",
  // });

  // // Get all the users for each day for the Prompto Portal
  const labels = buildLabelsAndTimestampsForLast30Days();
  const timeRangeList = labels.map((x) => {
    return {
      startTimestamp: x.startTimestamp,
      endTimestamp: x.endTimestamp
    };
  });

  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [timeRangeList],
  //   call: getAllUsersWithActionsForTimeRange,
  //   stateName: "usersWithActionsPerDay",
  // });

  // // Get all the users and their last action
  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [],
  //   call: getAllUsersWithLatestActivity,
  //   stateName: "usersWithLatestActivity",
  // });

  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [timeRangeList],
  //   call: getTotalShowcaseVisitorsForTimeRange,
  //   stateName: "showcaseVisitorsForTimeRange",
  // });

  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [timeRangeList],
  //   call: getUniqueShowcaseVisitorsForTimeRange,
  //   stateName: "uniqueShowcaseVisitorsForTimeRange",
  // });

  // // Get total visitor count for showcase
  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [],
  //   call: getTotalShowcaseVisitorsCount,
  //   stateName: "totalShowcaseVisitors",
  // });

  // // Get total sharecode count for showcase
  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [],
  //   call: getTotalSharecodesCount,
  //   stateName: "totalShowcaseSharecodes",
  // });

  // jobList.push({
  //   jobId: uuidv4(),
  //   params: [],
  //   call: getUniqueShowcaseVisitorsCountInLast30Days,
  //   stateName: "uniqueVisitorsInLast30Days",
  // });

  // Marketplace related calls

  jobList.push({
    jobId: uuidv4(),
    params: [],
    call: Marketplace.getTotalMarketplaceVisits,
    stateName: 'setTotalMarketplaceVisits'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [],
    call: Marketplace.getTotalUniqueMarketplaceVisits,
    stateName: 'setTotalUniqueMarketplaceVisits'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [timeRangeList],
    call: Marketplace.getTotalMarketplaceVisitsPerTimerange,
    stateName: 'setTotalMarketplaceVisitsPerTimerange'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [timeRangeList],
    call: Marketplace.getUniqueMarketplaceVisitsPerTimerange,
    stateName: 'setUniqueMarketplaceVisitsPerTimerange'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [timeRangeList],
    call: Marketplace.getTotalVisitsPerProjectPerTimerange,
    stateName: 'setMarketplaceProjectVisits'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [timeRangeList],
    call: Marketplace.getUniqueVisitsPerProjectPerTimerange,
    stateName: 'setUniqueVisitsPerProjectPerTimerange'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [],
    call: Marketplace.getTotalMarketplaceVisitsInLast30Days,
    stateName: 'setTotalMarketplaceVisitsInLast30Days'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [],
    call: Marketplace.getUniqueMarketplaceVisitsInLast30Days,
    stateName: 'setUniqueMarketplaceVisitsInLast30Days'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [],
    call: Marketplace.getTotalVisitsPerProjectInLast30Days,
    stateName: 'setTotalVisitsPerProjectInLast30Days'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [],
    call: Marketplace.getUniqueVisitsPerProjectInLast30Days,
    stateName: 'setUniqueVisitsPerProjectInLast30Days'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [timeRangeList],
    call: Marketplace.getTotalHomepageVisitorsPerTimerange,
    stateName: 'setTotalHomepageVisitorsPerTimerange'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [timeRangeList],
    call: Marketplace.getTotalProjectsVisitorsPerTimerange,
    stateName: 'setTotalProjectsVisitorsPerTimerange'
  });

  // // Get all the users for each day for the Prompto Showcase
  // // labels.forEach((label) => {
  // //   initialJobs.push({
  // //     jobId: uuidv4(),
  // //     params: {
  // //       timestamp: label.timestamp,
  // //       label: label.label,
  // //       isWeekend: label.isWeekend,
  // //       field: "totalVisitors",
  // //     },
  // //     call: getTotalShowcaseVisitorsForGivenDayCount(label.timestamp),
  // //     stateName: "showcaseVisitorsForGivenDay",
  // //   });

  // //   initialJobs.push({
  // //     jobId: uuidv4(),
  // //     params: {
  // //       timestamp: label.timestamp,
  // //       label: label.label,
  // //       isWeekend: label.isWeekend,
  // //       field: "uniqueVisitorsInLast30Days",
  // //     },
  // //     call: getUniqueShowcaseVisitorsInLast30DaysCount(label.timestamp),
  // //     stateName: "showcaseVisitorsForGivenDay",
  // //   });

  // //   initialJobs.push({
  // //     jobId: uuidv4(),
  // //     params: {
  // //       timestamp: label.timestamp,
  // //       label: label.label,
  // //       isWeekend: label.isWeekend,
  // //       field: "uniqueVisitors",
  // //     },
  // //     call: getUniqueShowcaseVisitorsForGivenDayCount(label.timestamp),
  // //     stateName: "showcaseVisitorsForGivenDay",
  // //   });
  // // });

  // // Get all projects with visitors
  // initialJobs.push({
  //   jobId: uuidv4(),
  //   call: getAllProjectsWithVisitors(),
  //   stateName: "allProjectsWithVisitors",
  // });

  // // Get all projects with visit duration
  // initialJobs.push({
  //   jobId: uuidv4(),
  //   call: getAllProjectsWithVisitDuration(),
  //   stateName: "allProjectsWithVisitDuration",
  // });

  return jobList;
};

export const getMinimumJobList = (timestamp: number) => {
  let jobList = [];

  jobList.push({
    jobId: uuidv4(),
    params: [],
    call: Marketplace.getTotalLeadsGeneratedPerProject,
    stateName: 'setTotalLeadsGeneratedPerProject'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [timestamp],
    call: Units.getAllUnitStatusUpdatesSinceTimestamp,
    stateName: 'setUnitStatusUpdates'
  });

  jobList.push({
    jobId: uuidv4(),
    params: [],
    call: Marketplace.getTotalMarketplaceAccounts,
    stateName: 'setTotalMarketplaceAccounts'
  });

  return jobList;
};

export const getInitialJobList = (timestamp: number) => {
  const initialJobs: Array<Job> = [];

  initialJobs.push(
    ...getMinimumJobList(timestamp),
    ...getMarketplaceDataJobs()
  );

  return initialJobs;
};
