import { useDataState } from "stores/DataStore";

// Styling
import styled from "styled-components";

import PortalUsersDashboard from "./components/PortalUsersDashboard";
import ShowcaseUsersDashboard from "./components/ShowcaseUsersDashboard";
import TotalCompanies from "./components/TotalCompanies";
import TotalShowcases from "./components/TotalShowcases";

const SPACING = 30;

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 60px);
  background: ${({ theme }) => theme.background100};
  padding-left: ${SPACING * 1.5}px;
  padding-right: ${SPACING * 1.5}px;
  box-sizing: border-box;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-top: ${SPACING - 10}px;
  height: fit-content;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const MainWrapper = styled(VerticalWrapper)`
  width: 100%;
  height: fit-content;
`;

const StyledPortalUsersDashboard = styled(PortalUsersDashboard)`
  height: fit-content;
  margin-right: ${SPACING}px;
  margin-bottom ${SPACING}px;
`;

const StyledShowcaseUsersDashboard = styled(ShowcaseUsersDashboard)`
  height: fit-content;
  margin-right: ${SPACING}px;
`;

const DashboardPage = () => {
  const { DataState } = useDataState();
  const {
    vaults,
    users,
    projects,
    totalUnitAmount,
    totalActiveUnitAmount,
    usersWithActionsPerDay,
    usersWithLatestActivity,
    showcaseVisitorsPerDay,
    uniqueShowcaseVisitorsPerDay,
    totalShowcaseVisitors,
    totalShowcaseSharecodes,
    uniqueVisitorsInLast30Days,
  } = DataState;

  return (
    <PageWrapper>
      <HorizontalWrapper>
        <MainWrapper>
          <StyledPortalUsersDashboard
            allUsers={users}
            usersWithActionsPerDay={usersWithActionsPerDay}
            usersWithLatestActivity={usersWithLatestActivity}
            title={"Portal Users"}
            infoCard1Title={"Total Users"}
            infoCard2Title={"Active in last 30 days"}
            infoCard3Title={"Daily Users"}
          />
          <StyledShowcaseUsersDashboard
            showcaseVisitorsPerDay={showcaseVisitorsPerDay}
            uniqueShowcaseVisitorsPerDay={uniqueShowcaseVisitorsPerDay}
            uniqueVisitorsInLast30Days={uniqueVisitorsInLast30Days}
            title={"Showcase Visitors"}
            infoCard1Title={"Unique Visitors in last 30 days"}
            infoCard2Title={"Daily Total Visitors"}
            infoCard3Title={"Daily Unique Visitors"}
          />
        </MainWrapper>
        <VerticalWrapper>
          <TotalCompanies
            allVaults={vaults}
            totalProjects={projects?.length ?? 0}
            totalUnits={totalUnitAmount}
            activeUnits={totalActiveUnitAmount}
          />

          <TotalShowcases
            totalVisits={totalShowcaseVisitors}
            totalSharecodes={totalShowcaseSharecodes}
          />
        </VerticalWrapper>
      </HorizontalWrapper>
    </PageWrapper>
  );
};

export default DashboardPage;
