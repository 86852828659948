import axios from 'axios';
import urlJoin from 'url-join';
import { getCurrentBackendEnvironment } from 'helpers';

/**
 * Builds the headers we need for a call
 * @param {string} sessionToken
 */
const buildHeaders = (sessionToken) => {
  const headers = {
    'X-Around-Api-Token': getCurrentBackendEnvironment().apiToken
  };
  if (sessionToken) {
    headers['X-Around-Session-Token'] = sessionToken;
  }
  return headers;
};

const buildMondayHeaders = () => {
  const headers = {
    Authorization: getCurrentBackendEnvironment().mondayAPIToken
  };
  return headers;
};

/**
 * Login into the Prompto API
 * @param {String} usernameOrEmail - Username or email
 * @param {String} password - Password
 */
export const login = (usernameOrEmail, password) => {
  if (!usernameOrEmail) {
    return Promise.reject(new Error('no username or email is given'));
  }

  if (!password) {
    return Promise.reject(new Error('no password is given'));
  }

  const body = {
    usernameOrEmail,
    password,
    entityObjectId: 'aroundmedia'
  };

  return axios.post(
    urlJoin(
      getCurrentBackendEnvironment().baseUrl,
      'users/login',
      `?_=${Date.now()}`
    ),
    body,
    {
      headers: buildHeaders()
    }
  );
};

/**
 * Return all the vaults
 * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_16
 * @param {string} sessionToken Session token of the user that is logged in
 */
export const getAllVaults = (sessionToken) => {
  return axios.get(
    urlJoin(
      getCurrentBackendEnvironment().baseUrl,
      'entities/aroundmedia/vaults',
      `?_=${Date.now()}`
    ),
    {
      params: {
        includeVaultPurchaseInfo: true
      },
      headers: buildHeaders(sessionToken)
    }
  );
};

export const getAllVaultsFiltered = (sessionToken) => {
  return axios.get(
    urlJoin(
      getCurrentBackendEnvironment().baseUrl,
      'entities/aroundmedia/vaults/filterByPlans',
      `?planIds=prompto-tiered-yearly-premium-euro&planIds=prompto-tiered-yearly-business-euro&planIds=prompto-tiered-yearly-essential-euro&planIds=prompto-tiered-monthly-premium-euro&planIds=prompto-tiered-monthly-business-euro&planIds=prompto-tiered-monthly-essential-euro&_=${Date.now()}`
    ),
    {
      headers: buildHeaders(sessionToken)
    }
  );
};

/**
 * Get all the user that can access the given vault
 * @param {string} sessionToken
 */
export const getAllUsersThatCanAccessVault = (vaultId, sessionToken) => {
  return axios.get(
    urlJoin(
      getCurrentBackendEnvironment().baseUrl,
      'vaults',
      vaultId,
      'users',
      `?_=${Date.now()}`
    ),
    {
      headers: buildHeaders(sessionToken)
    }
  );
};

/**
 * Get all the projects for the given vault
 * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_14
 * @param {string} vaultObjectId The object id of the current vault
 * @param {string} sessionToken
 */
export const getAllProjects = (vaultObjectId, sessionToken) => {
  return axios.get(
    urlJoin(
      getCurrentBackendEnvironment().baseUrl,
      'vaults',
      vaultObjectId,
      'projectSections',
      `?_=${Date.now()}`
    ),
    {
      params: {
        includeUnitList: true
      },
      headers: buildHeaders(sessionToken)
    }
  );
};

/**
 * Get Navigation Collection
 * @param {string} navigationCollectionObjectId
 * @param {string} sessionToken
 */
export const getNavigationCollection = (
  navigationCollectionObjectId,
  sessionToken
) => {
  const env = getCurrentBackendEnvironment();

  return axios.get(
    urlJoin(
      env.baseUrl,
      'navigationcollections',
      navigationCollectionObjectId,
      `?_=${Date.now()}`
    ),
    {
      headers: buildHeaders(sessionToken)
    }
  );
};

export const getContentCollection = (contentCollectionObjectId) => {
  return axios.get(
    urlJoin(
      getCurrentBackendEnvironment().baseUrl,
      'contentcollections',
      contentCollectionObjectId,
      `?_=${Date.now()}`
    ),
    {
      headers: buildHeaders()
    }
  );
};

/**
 * Get a token to access the google storage
 * https://devapiv2.vr-tual.media/asciidoc/index.html#_getstorageaccesstoken
 * @param {string} vaultId
 * @param {string} sessionToken
 * @returns
 */
export const vaultGetUploadAccessToken = (sessionToken) => {
  return axios.get(
    urlJoin(
      getCurrentBackendEnvironment().baseUrl,
      'vaults',
      getCurrentBackendEnvironment().promptoVault,
      '/catalogue/storage/token',
      `?_=${Date.now()}`
    ),
    {
      headers: buildHeaders(sessionToken)
    }
  );
};

export const getJsonFile = (fileUrl) => {
  return axios.get(fileUrl);
};

export const getMondayBoardItemFromProjectId = (projectId) => {
  const query = `{items_page_by_column_values (limit: 50, board_id: ${
    getCurrentBackendEnvironment().mondayBoardId
  }, columns: [{column_id: "${
    getCurrentBackendEnvironment().mondayProjectColumnId
  }", column_values: ["${projectId}"]}]) { cursor items { id name } } }`;

  return axios.post(
    urlJoin(getCurrentBackendEnvironment().mondayBaseUrl),
    {
      query
    },
    {
      headers: buildMondayHeaders()
    }
  );
};

export const getAllMondayProjects = () => {
  const query = `{ boards (ids: ${
    getCurrentBackendEnvironment().mondayBoardId
  }){ items_page (limit: 200) { items {id name column_values { id  text value } } } } }`;
  return axios.post(
    urlJoin(getCurrentBackendEnvironment().mondayBaseUrl),
    {
      query
    },
    {
      headers: buildMondayHeaders()
    }
  );
};

export const updateLeadAmountForProject = (itemId, leadAmount) => {
  const query = `mutation {change_column_value(item_id: ${itemId}, board_id: ${
    getCurrentBackendEnvironment().mondayBoardId
  }, column_id: "${
    getCurrentBackendEnvironment().mondayLeadsThisMonthColumnId
  }", value: "${leadAmount}") {id}}`;

  return axios.post(
    urlJoin(getCurrentBackendEnvironment().mondayBaseUrl),
    {
      query
    },
    {
      headers: buildMondayHeaders()
    }
  );
};

export const sendUnitSoldMessageToSlack = (companyName, unitName) => {
  const webhookUrl =
    'https://hooks.slack.com/services/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';
  const corsProxyUrl = 'https://corsproxy.io/?';
  const fullUrl = corsProxyUrl + webhookUrl;

  return axios.post(fullUrl, {
    blocks: [
      {
        type: 'divider'
      },
      {
        type: 'rich_text',
        elements: [
          {
            type: 'rich_text_section',
            elements: [
              {
                type: 'text',
                text: `${companyName} `,
                style: {
                  italic: true,
                  bold: true
                }
              },
              {
                type: 'text',
                text: 'has just sold unit '
              },
              {
                type: 'text',
                text: `${unitName}! `,
                style: {
                  italic: true,
                  bold: true
                }
              },
              {
                type: 'emoji',
                name: 'tada'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        text: {
          type: 'plain_text',
          text: ' '
        }
      },
      {
        type: 'divider'
      }
    ]
  });
};

export const getAllMetaCampaigns = () => {
  return axios.get(
    'https://graph.facebook.com/v19.0/act_599346272029704/campaigns',
    {
      params: {
        fields: 'name, status, stop_time',
        access_token:
          'EAAFkmxt66E8BO7aK8PJZBcO4ZAARsLQrDEgrNuRi6VZAGnpdsQo6aldt4aX3IQCldU9GH4VRp1KW8mX5v4cJdyLowxNCt7rrj5enaQeVrRqZCvcRk7YBotd8qsm4G0BRbqvPatfIjQvEUvhRMdbvZByO1LHRxmBMdePAbWMHJcW2gedyRO9mwQZCZCkcqXYDwZDZD',
        limit: 500
      }
    }
  );
};

export const getInsightsForMetaCampaign = (campaignId, selectedDataPreset) => {
  return axios.get(`https://graph.facebook.com/v19.0/${campaignId}/insights`, {
    params: {
      date_preset: selectedDataPreset,
      access_token:
        'EAAFkmxt66E8BO7aK8PJZBcO4ZAARsLQrDEgrNuRi6VZAGnpdsQo6aldt4aX3IQCldU9GH4VRp1KW8mX5v4cJdyLowxNCt7rrj5enaQeVrRqZCvcRk7YBotd8qsm4G0BRbqvPatfIjQvEUvhRMdbvZByO1LHRxmBMdePAbWMHJcW2gedyRO9mwQZCZCkcqXYDwZDZD'
    }
  });
};
