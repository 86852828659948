import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDataState } from 'stores/DataStore';

import {
  getInitialJobList,
  getMarketplaceDataJobs,
  getMinimumJobList
} from './helpers/DataLoaderHelper';

const DataLoader = () => {
  const location = useLocation();
  const { pathname } = location;

  const { DataStateDispatch, DataState } = useDataState();
  const { jobList, activeJobs } = DataState;

  const [initialized, setInitialized] = useState<boolean>(false);
  const [checkNextJob, setCheckNextJob] = useState<boolean>(false);

  const initializeMarketplaceCalls = useCallback(() => {
    DataStateDispatch({
      type: 'addDataCollectionJob',
      payload: getInitialJobList(new Date().getTime() - 60000)
    });

    setInterval(() => {
      setCheckNextJob(true);
    }, 1000);

    setInterval(() => {
      DataStateDispatch({
        type: 'addDataCollectionJob',
        payload: getMinimumJobList(new Date().getTime() - 60000)
      });
    }, 60000);

    setInterval(() => {
      DataStateDispatch({
        type: 'addDataCollectionJob',
        payload: getMarketplaceDataJobs()
      });
    }, 60000 * 10);
  }, [DataStateDispatch]);

  //const initializeReportingCalls = () => {};

  // Initialize list of jobs
  useEffect(() => {
    if (!initialized && pathname.includes('marketplace')) {
      setInitialized(true);
      initializeMarketplaceCalls();
    }

    // if (!initialized && pathname.includes('marketplace')) {
    //   setInitialized(true);
    //   initializeReportingCalls();
    // }
  }, [initialized, pathname, initializeMarketplaceCalls]);

  useEffect(() => {
    if (checkNextJob) {
      setCheckNextJob(false);

      if (jobList?.length > 0) {
        // Allow only 2 jobs at a time
        if (activeJobs?.length < 2) {
          DataStateDispatch({
            type: 'startNewJob'
          });
        }
      }
    }
  }, [checkNextJob, jobList, activeJobs, DataStateDispatch]);

  // Manage the active jobs and perform the needed calls
  useEffect(() => {
    if (activeJobs?.length > 0) {
      activeJobs.forEach((job: any) => {
        if (job && !job.inProgress) {
          // Mark job being in progress
          DataStateDispatch({
            type: 'markJobInProgress',
            payload: job
          });

          job.call(...job.params).then((result: any) => {
            DataStateDispatch({
              type: job.stateName,
              payload: result
            });

            // Remove job from active list when done
            DataStateDispatch({
              type: 'finishJob',
              payload: job
            });
          });
        }
      });
    }
  }, [activeJobs, DataStateDispatch]);

  return <></>;
};

export default DataLoader;
