import {
  convertMilliSecondsToReadableVersion,
  dateToDDMMYY_HHMM,
} from "helpers";

// Styling
import styled, { StyledProps } from "styled-components";

const Wrapper = styled.div<StyledProps<{ columnTemplate: string }>>`
  display: grid;
  grid-template-columns: ${({ columnTemplate }) => columnTemplate};
  padding: 9px 8px;
  box-shadow: inset 0 -1px 0 0 #e3e5e8;
  cursor: pointer;
`;

const Value = styled.div``;

export interface UserInfoCardProps {
  project: any;
  columnTemplate: any;
  onClick: any;
}

const ShowcaseInfoCard = ({
  project,
  columnTemplate,
  onClick,
}: UserInfoCardProps) => {
  const createdAt = dateToDDMMYY_HHMM(new Date(project.createdAt));
  const lastActivity = dateToDDMMYY_HHMM(new Date(project.lastActivity));

  return (
    <Wrapper columnTemplate={columnTemplate} onClick={onClick}>
      <Value>{project.projectTitle}</Value>
      <Value>{project.company}</Value>
      <Value>{createdAt}</Value>
      <Value>{lastActivity}</Value>
      <Value>{project.totalVisits}</Value>
      <Value>{"--"}</Value>
      <Value>
        {convertMilliSecondsToReadableVersion(
          project.totalTimeSpent / project.sessions.length
        )}
      </Value>
      <Value>
        {convertMilliSecondsToReadableVersion(project.totalTimeSpent)}
      </Value>
    </Wrapper>
  );
};

export default ShowcaseInfoCard;
