import { getCurrentBackendEnvironment } from "helpers";
import { convertRecordToUser, isUser } from "./models/GUser";
import { convertRecordToVault, isVault } from "./models/GVault";
import { QueryResult, Record } from "./models/Node";
import { Unit } from "./models/GUnit";
import { convertRecordToProject, isProject } from "./models/GProject";

const neo4j = require("neo4j-driver");

// Init Neo4j
const Neo4jDriver = neo4j.driver(
  getCurrentBackendEnvironment().neo4jUrl,
  neo4j.auth.basic("neo4j", getCurrentBackendEnvironment().neo4jPass),
  { disableLosslessIntegers: true }
);

export const runQuery = (query: string, params?: any) => {
  const Neo4jSession = Neo4jDriver.session({
    defaultAccessMode: neo4j.session.READ,
  });

  return Neo4jSession.run(query, params)
    .then((result: any) => {
      return result;
    })
    .finally(() => {
      Neo4jSession.close();
    });
};

// Return all vault objects
export const getAllVaults = () => {
  return runQuery(`MATCH (v:Vault) RETURN v`).then((result: QueryResult) => {
    const allVaults = result.records.map((x: Record) => {
      return convertRecordToVault(x);
    });

    return allVaults.filter(isVault);
  });
};

// Return all user objects
export const getAllUsers = () => {
  return runQuery(`MATCH (u:User) RETURN u`).then((result: QueryResult) => {
    const allUsers = result.records.map((x: Record) => {
      return convertRecordToUser(x);
    });

    return allUsers.filter(isUser);
  });
};

// Return all user objects with the timestamp of last action
export const getAllUsersWithLatestActivity = () => {
  return runQuery(
    `MATCH (u:User)-[c:CREATED]->(a: Action)
    MATCH (u)-[:BELONGS_TO]->(v:Vault)
    RETURN u, MAX(a.createdAt), v.name`
  ).then((result: QueryResult) => {
    const data: Array<any> = [];

    result.records.forEach((x) => {
      const user = convertRecordToUser(x);
      const lastActivity = x._fields[1];
      const company = x._fields[2];

      let userAdded = data.find((y) => y.objectId === user?.objectId);
      if (userAdded) {
        userAdded.companies = [...userAdded.companies, company];
      } else {
        data.push({ ...user, lastActivity, companies: [company] });
      }
    });

    return data;
  });
};

export const getAllUsersWithActionsForTimeRange = (timeRangeList: any) => {
  return runQuery(
    `UNWIND $timeRangeList as range
    MATCH (u: User)-[c:CREATED]->(a: Action)
    WHERE a.createdAt >= range.startTimestamp AND a.createdAt <= range.endTimestamp
    RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp, count(u), u`,
    { timeRangeList }
  ).then((result: QueryResult) => {
    const data: Array<any> = [];

    result.records.forEach((x) => {
      const start = x._fields[0];
      const end = x._fields[1];
      const user = x._fields[3];

      let timeRangeAdded = data.find(
        (y: any) => y.startTimestamp === start && y.endTimestamp === end
      );
      if (timeRangeAdded) {
        let userAdded = timeRangeAdded.users.find(
          (y: any) => y.elementId === user.elementId
        );

        if (!userAdded) {
          timeRangeAdded.users.push(user);
        }
      } else {
        data.push({
          startTimestamp: start,
          endTimestamp: end,
          users: [user],
        });
      }
    });

    return data;
  });
};

// Return the number of total units
export const getAllUnitsCount = () => {
  return runQuery(`MATCH (u:Unit) RETURN count(u);`).then(
    (result: QueryResult) => {
      return result.records[0]._fields[0];
    }
  );
};

// Return all units with the latest updates on each state
export const getAllUnitsWithLatestStatusUpdate = () => {
  return runQuery(
    `MATCH (a:Action {name: "unitStatusUpdate"})-[r:REFERS]->(u:Unit) RETURN u, MAX(a.createdAt), a.newValue;`
  ).then((result: QueryResult) => {
    const data: Array<any> = [];

    result.records.forEach((x) => {
      const unit = x._fields[0];
      const timestampOfChange = x._fields[1];
      const status = x._fields[2];

      let unitAdded = data.find((y) => y.elementId === unit.elementId);
      if (unitAdded) {
        unitAdded.actions = [
          ...unitAdded.actions,
          { timestampOfChange, status },
        ];
      } else {
        data.push({ ...unit, actions: [{ timestampOfChange, status }] });
      }
    });

    return data;
  });
};

export const getAllActiveUnitsCount = () => {
  return getAllUnitsWithLatestStatusUpdate().then((result: Array<Unit>) => {
    let activeUnits = 0;
    result.forEach((unit) => {
      const max = unit.actions.reduce(function (prev: any, current: any) {
        return prev.timestampOfChange > current.timestampOfChange
          ? prev
          : current;
      });

      if (max && (max.status === "AVAILABLE" || max.status === "IN_OPTION"))
        activeUnits++;
    });

    return activeUnits;
  });
};

// Return all the projects
export const getAllProjects = () => {
  return runQuery(`MATCH (p:Project) RETURN p;`).then((result: QueryResult) => {
    const allProjects = result.records.map((x: Record) => {
      return convertRecordToProject(x);
    });

    return allProjects.filter(isProject);
  });
};

// Return the number of total projects
export const getAllProjectsCount = () => {
  return runQuery(`MATCH (p:Project) RETURN count(p);`).then(
    (result: QueryResult) => {
      return result.records[0]._fields[0];
    }
  );
};

// Return the number of total sessions for the showcase
export const getTotalShowcaseVisitorsCount = () => {
  return runQuery(
    `MATCH (p:PromptoSession {type: "showcase"}) RETURN count(p)`
  ).then((result: QueryResult) => {
    return result.records[0]._fields[0];
  });
};

// Return the number of total sharecodes
export const getTotalSharecodesCount = () => {
  return runQuery(`MATCH (s:ShareItem) RETURN count(s)`).then(
    (result: QueryResult) => {
      return result.records[0]._fields[0];
    }
  );
};

export const getUniqueShowcaseVisitorsCount = (dayTimestamp: number) => {
  const timestamp = dayTimestamp + 86400000;

  return runQuery(
    `MATCH (v:Visitor) WHERE v.createdAt <= ${timestamp} RETURN count(v), v.ipAddress`
  ).then((result: QueryResult) => {
    return result.records.length;
  });
};

export const getUniqueShowcaseVisitorsCountInLast30Days = () => {
  const startOfDay = new Date();
  startOfDay.setUTCHours(0, 0, 0, 0);
  const timestamp = startOfDay.getTime() + 86400000 - 2629743000;

  return runQuery(
    `MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"}) WHERE a.createdAt >= ${timestamp} RETURN count(v), v.ipAddress`
  ).then((result: QueryResult) => {
    return result.records.length;
  });
};

export const getUniqueShowcaseVisitorsForTimeRange = (timeRangeList: any) => {
  return runQuery(
    `
  UNWIND $timeRangeList as range
  MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type: "showcase"})
  WHERE p.createdAt >= range.startTimestamp AND p.createdAt <= range.endTimestamp
  RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp, count(distinct v.ipAddress) as visits`,
    { timeRangeList }
  ).then((result: QueryResult) => {
    const data = result.records.map((x) => {
      return {
        startTimestamp: x._fields[0],
        endTimestamp: x._fields[1],
        count: x._fields[2],
      };
    });

    return data;
  });
};

export const getTotalShowcaseVisitorsForTimeRange = (timeRangeList: any) => {
  return runQuery(
    `
  UNWIND $timeRangeList as range
  MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type: "showcase"})
  WHERE p.createdAt >= range.startTimestamp AND p.createdAt <= range.endTimestamp
  RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp,count(p)`,
    { timeRangeList }
  ).then((result: QueryResult) => {
    const data = result.records.map((x) => {
      return {
        startTimestamp: x._fields[0],
        endTimestamp: x._fields[1],
        count: x._fields[2],
      };
    });

    return data;
  });
};

export const getUniqueShowcaseVisitorsForGivenDayCount = (
  dayTimestamp: number
) => {
  const timestamp = dayTimestamp;
  const endOfDay = dayTimestamp + 86400000;

  return runQuery(`MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type: "showcase"})
  WHERE p.createdAt >= ${timestamp} AND p.createdAt <= ${endOfDay}
  RETURN count(v), v.ipAddress`).then((result: QueryResult) => {
    return result.records.length;
  });
};

export const getAllProjectsWithVisitors = () => {
  return runQuery(`MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type: "showcase"})-[:REFERS]->(s:ShareItem)-[:BELONGS_TO]->(proj:Project)-[:BELONGS_TO]->(vault:Vault)
  RETURN proj.objectId, proj.title, vault.name, proj.createdAt, max(p.createdAt), count(p)`).then(
    (result: QueryResult) => {
      const data: Array<any> = [];

      result.records.forEach((x) => {
        data.push({
          objectId: x._fields[0],
          projectTitle: x._fields[1],
          company: x._fields[2],
          createdAt: x._fields[3],
          lastActivity: x._fields[4],
          totalVisits: x._fields[5],
        });
      });

      return data;
    }
  );
};

export const getAllProjectsWithVisitDuration = () => {
  return runQuery(`MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type: "showcase"})-[:REFERS]->(s:ShareItem)-[:BELONGS_TO]->(proj:Project)-[:BELONGS_TO]->(vault:Vault)
  RETURN proj.objectId, proj.title, p`).then((result: QueryResult) => {
    const data: Array<any> = [];

    result.records.forEach((x) => {
      const objectId = x._fields[0];
      const projectTitle = x._fields[1];
      const session = x._fields[2];

      let projectAdded = data.find((y) => y.objectId === objectId);
      if (projectAdded) {
        projectAdded.sessions = [...projectAdded.sessions, session];
      } else {
        data.push({ objectId, projectTitle, sessions: [session] });
      }
    });

    return data;
  });
};

export * from "./marketplace/marketplace";
