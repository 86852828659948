import { User } from "helpers/neo4j/models/GUser";

// Styling
import styled, { StyledProps } from "styled-components";

const Wrapper = styled.tr<StyledProps<{ columnTemplate: string }>>`
  display: grid;
  grid-template-columns: ${({ columnTemplate }) => columnTemplate};
  padding: 9px 8px;
  box-shadow: inset 0 -1px 0 0 #e3e5e8;
`;

const Value = styled.td``;

export interface UserInfoCardProps {
  user: User;
  columnTemplate: any;
}

const UserInfoCard = ({ user, columnTemplate }: UserInfoCardProps) => {
  return (
    <Wrapper columnTemplate={columnTemplate}>
      <Value>{user.email}</Value>
      <Value>{user.companies[0]}</Value>
      <Value>{user.lastActivity}</Value>
    </Wrapper>
  );
};

export default UserInfoCard;
