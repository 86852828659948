import React from "react";

// Styling
import styled, { StyledProps } from "styled-components";

const StyledButton = styled.button<StyledProps<any>>`
  border: none;
  font-size: 16px;
  padding: 10px 20px;
  font: inherit;
  border-radius: 2px;
  height: ${(props) => props.height}px;
  color: "white";
  background-color: ${({ theme }) => theme.accentColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: ${(props) => props.theme.accentFont};
  user-select: none;
`;

export interface ButtonProps {
  text: string;
  onButtonClick: Function;
  disabled: boolean;
}

export const Button = ({ text, onButtonClick, disabled }: ButtonProps) => {
  return (
    <StyledButton disabled={disabled} onClick={onButtonClick}>
      {text}
    </StyledButton>
  );
};

export default Button;
