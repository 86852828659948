// Styling
import styled from "styled-components";

import { Chart } from "react-chartjs-2";
import { User } from "helpers/neo4j/models/GUser";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { buildLabelsAndTimestampsForLast30Days } from "../helpers/DashboardHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ChartDataLabels,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Filler,
  Legend
);

const MainWrapper = styled.div`
  background: ${({ theme }) => theme.background};
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  padding: 15px;
  flex-grow: 1;
`;

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
`;

const VerticalWrapper = styled.div`
  flex-basis: 20%;
  display: flex;
  flex-flow: column;
  margin-right: 15px;
`;

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.mainTextColor};
`;

const InfoCard = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
`;

const TotalUsers = styled(InfoCard)`
  background: ${({ theme }) => theme.background100};
`;
const ActiveInLast30 = styled(InfoCard)`
  background: ${({ theme }) => theme.background200};
`;
const ActiveUsers = styled(InfoCard)`
  background: linear-gradient(
    90deg,
    rgba(21, 171, 144, 0.6),
    rgba(249, 239, 209, 0.6)
  );
`;

const InfoCardLabel = styled.div`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.mainTextColor};
`;

const InfoCardValue = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.boldTextColor};
`;

const GraphContainer = styled.div`
  width: 80%;
`;

export interface ActiveUsersDashboardProps {
  className?: string;
  allUsers: Array<User>;
  usersWithActionsPerDay: Array<any>;
  usersWithLatestActivity: Array<any>;
  title: string;
  infoCard1Title: string;
  infoCard2Title: string;
  infoCard3Title: string;
}

const ActiveUsersDashboard = ({
  className,
  allUsers,
  usersWithActionsPerDay,
  usersWithLatestActivity,
  title,
  infoCard1Title,
  infoCard2Title,
  infoCard3Title,
}: ActiveUsersDashboardProps) => {
  const labels = buildLabelsAndTimestampsForLast30Days();

  let biggestValue = 0;
  if (usersWithActionsPerDay) {
    usersWithActionsPerDay.forEach((x) => {
      if (x.users.length > biggestValue) {
        biggestValue = x.users.length;
      }
    });
  }

  let totalVisitsData = [];
  if (usersWithActionsPerDay && labels) {
    totalVisitsData = labels.map((x) => {
      const matchedRow = usersWithActionsPerDay.find((y) => {
        return (
          y.startTimestamp === x.startTimestamp &&
          y.endTimestamp === x.endTimestamp
        );
      });

      return matchedRow ? matchedRow.users.length : 0;
    });
  }

  type alignment = "end" | "start" | "center";
  const align: alignment = "end";

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        anchor: align,
        offset: 10,
        opacity: 1,
        color: "black",
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      yAxis: {
        min: 0,
        max: biggestValue + Math.round(biggestValue * 0.1),
        grid: {
          z: 1,
        },
      },
      xAxis: {
        stacked: true,
        grid: {
          z: 1,
        },
      },
    },
  };

  const data = {
    labels: labels
      .map((x) => {
        return x.label;
      })
      .reverse(),
    datasets: [
      {
        type: "line" as const,
        fill: true,
        label: "Active Users",
        data: totalVisitsData.reverse(),
        borderColor: "rgb(21, 171, 144,0.5)",
        backgroundColor: "rgb(21, 171, 144,0.5)",
        z: 2,
      },
      {
        type: "bar" as const,
        fill: true,
        data: labels?.reverse().map((x: any) => {
          if (x.isWeekend) return 50000;
          return "";
        }),
        barPercentage: 1.3,
        borderColor: "rgb(241, 242, 243,1)",
        backgroundColor: "rgb(241, 242, 243,1)",
      },
    ],
  };

  const today = labels[29];
  const endOfThatDay = today?.startTimestamp + 86400000;
  const thatDayAMonthBefore = today?.startTimestamp - 2629743000;

  const activeUsersInLast30days = usersWithLatestActivity.filter((x: any) => {
    return (
      x.lastActivity <= endOfThatDay && x.lastActivity >= thatDayAMonthBefore
    );
  }).length;

  return (
    <MainWrapper className={className}>
      <Title>{title}</Title>
      <HorizontalWrapper>
        <VerticalWrapper>
          <TotalUsers>
            <InfoCardLabel>{infoCard1Title}</InfoCardLabel>
            <InfoCardValue>
              {usersWithLatestActivity?.length ?? 0}
            </InfoCardValue>
          </TotalUsers>
          <ActiveInLast30>
            <InfoCardLabel>{infoCard2Title}</InfoCardLabel>
            <InfoCardValue>{activeUsersInLast30days}</InfoCardValue>
          </ActiveInLast30>
          <ActiveUsers>
            <InfoCardLabel>{infoCard3Title}</InfoCardLabel>
            <InfoCardValue>{totalVisitsData[29] ?? 0}</InfoCardValue>
          </ActiveUsers>
        </VerticalWrapper>
        <GraphContainer>
          <Chart type="bar" options={options} data={data} />
        </GraphContainer>
      </HorizontalWrapper>
    </MainWrapper>
  );
};

export default ActiveUsersDashboard;
