import styled from "styled-components";
import ShowcaseStatistics from "./components/ShowcaseStatistics";
import { useHistory } from "react-router";

const MainWrapper = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.background100};
`;

const BackButton = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
`;

export interface ProjectDetailPageProps {}

const ProjectDetailPage = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <MainWrapper>
      <BackButton onClick={goBack}>{"<- Back"}</BackButton>
      <ShowcaseStatistics />
    </MainWrapper>
  );
};

export default ProjectDetailPage;
