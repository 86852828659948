import { useDataState } from "stores/DataStore";

import PaginationControls from "../../../components/PaginationControls";

import { useCSVDownloader } from "react-papaparse";

// Styling
import styled, { StyledProps } from "styled-components";
import { useEffect, useState } from "react";
import UserInfoCard from "./UserInfoCard";
import { User } from "helpers/neo4j/models/GUser";
import SortByField from "../../../components/SortByField";
import {
  dateToDDMMYY_HHMM,
  removeAllPromptoUsers,
  searchEntities,
  sortEntities,
} from "helpers";
import SearchField from "components/SearchField";

const SPACING = 30;

const MainWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-top: ${SPACING - 10}px;
  height: fit-content;
  align-items: center;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const Title = styled.h1`
  margin-right: 50px;
`;

const ListBackground = styled.div`
  background: ${({ theme }) => theme.background};
  padding: 16px 32px 8px;
`;

const ListWrapper = styled.table`
  display: flex;
  flex-flow: column;
`;

const NavBarWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const TableHeader = styled.div<StyledProps<{ columnTemplate: string }>>`
  display: grid;
  grid-template-columns: ${({ columnTemplate }) => columnTemplate};
  grid-template-rows: 40px;
  padding-left: 10px;
  box-shadow: inset 0 -1px 0 0 #e3e5e8;
`;

const UserList = () => {
  const { DataState } = useDataState();
  const { usersWithLatestActivity } = DataState;

  const [perPage, setPerPage] = useState({ label: 200, value: 200 });
  const [currentStart, setCurrentStart] = useState(0);

  const [sortedField, setSortedField] = useState("lastActivity");
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const [filteredList, setFilteredList] = useState<Array<any>>([]);

  const { CSVDownloader } = useCSVDownloader();

  const headers = ["email", "company", "lastActivity"];
  const columnTemplate = "50% 37% 13%";

  useEffect(() => {
    if (usersWithLatestActivity?.length > 0) {
      let filteredL = sortEntities(
        usersWithLatestActivity,
        sortedField,
        sortAscending,
        false
      );

      filteredL = removeAllPromptoUsers(filteredL);

      if (searchValue) {
        filteredL = searchEntities(searchValue, filteredL);
      }

      filteredL = filteredL.map((x: any) => {
        return {
          ...x,
          lastActivity: dateToDDMMYY_HHMM(new Date(x.lastActivity)),
          createdAt: dateToDDMMYY_HHMM(new Date(x.createdAt)),
        };
      });
      setFilteredList([...filteredL]);
    }
  }, [usersWithLatestActivity, sortAscending, sortedField, searchValue]);

  return (
    <MainWrapper>
      <VerticalWrapper>
        <HorizontalWrapper>
          <Title>Users</Title>
          <SearchField
            onChange={(e: string) => {
              setSearchValue(e);
            }}
            placeholder="search"
            found={0}
            showResultsInside={true}
            canGrow={false}
            enableSearchOnEnterPress={false}
            onClear={() => {}}
            onFocus={() => {}}
          />
          <CSVDownloader
            data={filteredList}
            filename={`Prompto_Users`}
            bom={true}
          >
            Download .csv
          </CSVDownloader>
        </HorizontalWrapper>
        <ListBackground>
          <TableHeader columnTemplate={columnTemplate}>
            {headers.map((header, index) => {
              return (
                <SortByField
                  key={`sortfield${index}`}
                  // @ts-ignore
                  label={header}
                  fieldToSort={header}
                  currentFieldToSort={sortedField}
                  onSortByChanged={(sortOnField, isAscending) => {
                    setSortedField(sortOnField);
                    setSortAscending(isAscending);
                  }}
                />
              );
            })}
          </TableHeader>
          <ListWrapper>
            {filteredList.map((user: User, index: number) => {
              if (
                index >= currentStart &&
                index < currentStart + perPage.value
              ) {
                return (
                  <UserInfoCard
                    key={`userCard${index}`}
                    user={user}
                    columnTemplate={columnTemplate}
                  />
                );
              }

              return <></>;
            })}
          </ListWrapper>
          <NavBarWrapper>
            <PaginationControls
              perPage={perPage}
              currentPage={currentStart}
              totalEntries={filteredList?.length ?? 0}
              changePerPage={(e: any) => {
                setPerPage(e);
                setCurrentStart(0);
                // if (onPerPageChange)
                //     onPerPageChange(e?.value);
              }}
              toPreviousPage={() => {
                if (currentStart > 0) {
                  setCurrentStart(
                    Math.min(Math.max(currentStart - perPage.value, 0), 1000)
                  );
                }
              }}
              toNextPage={() => {
                if (currentStart + perPage.value < filteredList.length) {
                  setCurrentStart(currentStart + perPage.value);
                }
              }}
            />
          </NavBarWrapper>
        </ListBackground>
      </VerticalWrapper>
    </MainWrapper>
  );
};

export default UserList;
