import qs from 'query-string';
import configs from 'config/config.json';

/**
 * Runs a querystring search on the url params, to fetch the data,
 * destructure it as you need params
 */
export const fetchSettingsFromURL = () => {
  return qs.parse(window.location.search, {
    parseBooleans: true,
    parseNumbers: true
  });
};

/**
 * Gets the current environment config
 */
export const getCurrentBackendEnvironment = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return configs['production'];
  }

  return configs['development'];
};

/**
 * Clamp a value between the specified min and max values.
 */
export const clamp = (value, min, max) => {
  return Math.max(Math.min(value, max), min);
};

/**
 * Convert date to format: 01.02.2021
 * @param {Date} date
 */
export const dateToDDMMYY = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = (day < 10 ? '0' : '') + day;
  const formattedMonth = (month < 10 ? '0' : '') + month;

  return `${formattedDay}.${formattedMonth}.${year}`;
};

export const getVaultForObjectId = (vaultObjectId, vaults) => {
  return vaults.find((x) => x.objectId === vaultObjectId);
};

export const getProjectListForVault = (vaultObjectId, projects) => {
  return projects.find((x) => x.vaultObjectId === vaultObjectId);
};

export const getUserListForVault = (vaultObjectId, users) => {
  return users.find((x) => x.vaultObjectId === vaultObjectId)?.users;
};
/**
 * returns a list of users with their roles from a roleList
 * @param {array} list
 */
export const getUsersFromRoleList = (list) => {
  let newUserList = [];

  for (const role in list) {
    const users = list[role];
    const usersToAdd = users.map((user) => ({
      ...user,
      role,
      status: user.type === 'User' ? 'Active' : 'Invited'
    }));

    newUserList = [...newUserList, ...usersToAdd];
  }

  return newUserList;
};

/**
 * Get the active users from a list of users.
 * Active users are users who last logged in the past 30 days according to the date passed
 * @param {*} users
 */
export const getActiveUsers = (users, comparingDate) => {
  const currentTime = comparingDate.getTime();

  const filteredUsers = users?.filter((user) => {
    const diff = (currentTime - user.lastModifiedAt) / 1000;

    // 2629743 = 1 month (avg. 30.44 days)
    if (diff < 2629743) {
      return true;
    }

    return false;
  });

  return filteredUsers;
};

export const getDateInDDMMYYFormat = (date) => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

  const formatted = `${day}-${month}-${date.getFullYear()}`;

  return formatted;
};

/**
 * Convert date to format: 01.02.2021, 12:05
 * @param {Date} date
 */
export const dateToDDMMYY_HHMM = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minutes = date.getMinutes();

  const formattedDay = (day < 10 ? '0' : '') + day;
  const formattedMonth = (month < 10 ? '0' : '') + month;
  const formattedHour = (hour < 10 ? '0' : '') + hour;
  const formattedMinutes = (minutes < 10 ? '0' : '') + minutes;

  return `${formattedDay}.${formattedMonth}.${year}, ${formattedHour}:${formattedMinutes}`;
};

/**
 * Get the specified field in the given order
 * @param {object} entity The entity to get the field from
 * @param {string} field The field we want
 */
export const getFieldValue = (entity, field) => {
  switch (field) {
    default:
    case 'lastActivity':
      return entity[field];
  }
};

/**
 * Sort the orders based on the sortfield in ascending or descending order
 * @param {array} entities The entities to be sorted
 * @param {string} sortField The field we want to sort on
 * @param {boolean} isAscending If we need to sort in an ascending or descending order
 * @param {boolean} toNumber if needed make sorting particular by numbers
 */
export const sortEntities = (entities, sortField, isAscending, toNumber) => {
  return entities?.sort((entityA, entityB) => {
    let a = getFieldValue(entityA, sortField);
    let b = getFieldValue(entityB, sortField);

    if (toNumber) {
      a = +a;
      b = +b;
    }

    if (typeof a === 'string') {
      const compareResult = a.localeCompare(b);

      return isAscending ? compareResult : -1 * compareResult;
    }

    if (a < b) {
      return isAscending ? -1 : 1;
    }
    if (a > b) {
      return isAscending ? 1 : -1;
    }

    return isAscending;
  });
};

export const removeAllPromptoUsers = (list) => {
  return list.filter((x) => {
    let isPromptoUser = false;
    const email = x.properties ? x.properties.email : x.email;

    if (!email) {
      return isPromptoUser;
    }

    isPromptoUser = email.includes('prompto');
    isPromptoUser |= email.includes('@around.media');
    isPromptoUser |= email.includes('qaoncloud');
    isPromptoUser |= email.includes('cactussoft');
    isPromptoUser |= email.includes('pierke');

    return !isPromptoUser;
  });
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
/* istanbul ignore next */
export const debounce = (func, wait, immediate) => {
  let timeout;
  /* istanbul ignore next */
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const searchEntities = (searchValue, list) => {
  return list.filter((x) => {
    let isMatch = false;
    for (const [, value] of Object.entries(x)) {
      if (String(value).includes(searchValue)) {
        isMatch = true;
      }
    }
    return isMatch;
  });
};

/**
 * Converts a given duration in milliseconds to a readable version
 * @param {number} duration in milliseconds
 * @returns Formatted string in format 0d 0h 0m 0s
 */
export const convertMilliSecondsToReadableVersion = (duration) => {
  var day, hour, minute, seconds;
  seconds = Math.floor(duration / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;

  // Return value including days
  if (day > 0) {
    return day + 'd ' + hour + 'h ' + minute + 'm ' + seconds + 's';
  }

  // Return value including hours
  if (hour > 0) {
    return hour + 'h ' + minute + 'm ' + seconds + 's';
  }

  return minute + 'm ' + seconds + 's';
};

export const getBasicChartOptions = (maxYValue, displayValues = true) => {
  const align = 'end';

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: displayValues,
        anchor: align,
        offset: 10,
        opacity: 1,
        color: 'black'
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      yAxis: {
        min: 0,
        max: maxYValue + 100,
        grid: {
          z: 1
        }
      },
      xAxis: {
        stacked: true,
        grid: {
          z: 1
        }
      }
    }
  };

  return options;
};
