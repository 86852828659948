// Styling
import styled, { StyledProps } from 'styled-components';

import LogoImage from 'resources/prompto-logo-full-black.svg';

import { useHistory } from 'react-router-dom';

const BarHeight = 60;

const MainWrapper = styled.div`
  width: 100%;
  height: ${BarHeight}px;
  background: ${({ theme }) => theme.background};
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.background200};
`;

const Logo = styled.img`
  height: ${BarHeight - 10}px;
`;

const NavigationList = styled.div`
  display: flex;
  flex-flow: row;
  margin-left: auto;
  margin-right: auto;
  column-gap: 50px;
`;

const NavigationButton = styled.div<StyledProps<{ active: boolean }>>`
  user-select: none;
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.boldTextColor : theme.mainTextColor};
`;

const navigationOptions: { [key: string]: string } = {
  marketplace: 'Marketplace',
  reporting: 'Reporting'
};

// const SoundButton = styled.div`
//   cursor: pointer;
//   background: ${({ theme }) => theme.background200};
//   padding: 4px;
//   border-radius: 4px;
// `;

// const audio1 = new Audio(sound1);
// const audio2 = new Audio(sound2);
// const audio3 = new Audio(sound3);
// const audio4 = new Audio(sound4);

const NavigationBar = () => {
  const history = useHistory();

  interface Button {
    key: string;
    onClick: any;
    text: string;
  }

  const buttons: Array<Button> = [];
  Object.keys(navigationOptions).forEach((key: string, index) => {
    buttons.push({
      key: key,
      onClick: () => {
        history.push(`/${key}`);
      },
      text: navigationOptions[key]
    });
  });

  return (
    <MainWrapper>
      <Logo src={LogoImage} />

      <NavigationList>
        {buttons.map((button: Button) => {
          return (
            <NavigationButton
              active={history.location.pathname.includes(button.key)}
              onClick={() => {
                button.onClick();
              }}
            >
              {button.text}
            </NavigationButton>
          );
        })}
      </NavigationList>
      {/* <Divider />
      <ProfileCard /> */}
      {/* <SoundButton
        onClick={() => {
          const song = Math.floor(Math.random() * (4 - 1 + 1) + 1);

          console.log(song);

          switch (song) {
            default:
            case 1:
              audio1.play();
              break;
            case 2:
              audio2.play();
              break;
            case 3:
              audio3.play();
              break;
            case 4:
              audio4.play();
              break;
          }
        }}
      >
        Test Sound
      </SoundButton> */}
      {/* <button
        onClick={() => {
          sendUnitSoldMessageToSlack("patrick", "karel");
        }}
      >
        Send message
      </button> */}
    </MainWrapper>
  );
};

export default NavigationBar;
