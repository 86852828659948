import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

// Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

// Helpers
import { debounce } from "helpers";

// Styling
import styled, { StyledProps } from "styled-components";

const Wrapper = styled.div<StyledProps<any>>`
  min-width: 100px;
  flex-grow: ${({ canGrow }) => (canGrow ? 1 : 0)};
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  color: ${(props) =>
    !props.disabled ? props.theme.primary100 : props.theme.gray300};
  border-bottom: 1px solid ${(props) => props.theme.gray300};
  transition: all 200ms ease;
  ${({ styles }) => styles}
`;

const InputWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-grow: 0;
`;

const TextInput = styled.input<StyledProps<any>>`
  font-size: 1rem;
  width: 100%;
  font-family: ${({ theme }) => theme.mainFontFamily}, "ProximaNova",
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  border: none;
  letter-spacing: 1.5px;
  outline: none;
  transition: 0.5s;
  text-align: center;
  color: ${(props) =>
    !props.disabled ? props.theme.primary100 : props.theme.gray300};
  flex-grow: ${(props) => (!props.disabled && props.isfocussed ? 1 : 0)};

  &::placeholder {
    color: ${(props) =>
      !props.disabled ? props.theme.primary100 : props.theme.gray300};
    text-transform: uppercase;
  }

  background-color: transparent;
  color: black;
  font-weight: 500;
  text-align: left;
  &::placeholder {
    text-transform: none;
    letter-spacing: normal;
    font-weight: 400;
  }
`;

const Icon = styled(FontAwesomeIcon)<StyledProps<any>>`
  font-size: 14px;
  color: ${(props) =>
    !props.disabled ? props.theme.primary100 : props.theme.gray300};
`;

const SearchIcon = styled(Icon)`
  margin-right: 20px;
  ${({ styles }) => styles}
`;

const ClearIcon = styled(Icon)`
  ${({ styles }) => styles}
`;

const ClearIconWrapper = styled.div<StyledProps<any>>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  cursor: ${({ disabled, isfocussed }) =>
    !disabled && isfocussed ? "pointer" : "default"};
  margin-left: 0;
  &:hover {
    cursor: pointer;
  }
`;

const FoundCount = styled.span`
  font-size: 0.75rem;
  margin: 0 6px;
  color: ${({ theme }) => theme.primary100};
  white-space: nowrap;
`;

const debounceChange = debounce((onChange: any, value: any) => {
  onChange(value);
}, 450);

export interface SearchFieldProps {
  onChange: any;
  placeholder: string;
  disabled?: boolean;
  found: number;
  showResultsInside: boolean;
  canGrow: boolean;
  enableSearchOnEnterPress: boolean;
  onClear: any;
  onFocus: any;
  className?: string;
  initValue?: string;
}

const SearchField = forwardRef(
  (
    {
      onChange,
      placeholder,
      disabled,
      className,
      initValue,
      found,
      showResultsInside,
      canGrow,
      enableSearchOnEnterPress,
      onClear,
      onFocus,
    }: SearchFieldProps,
    ref
  ) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isFocussed, setIsFocussed] = useState(false);
    const [value, setValue] = useState(initValue ?? "");

    const inputRef = useRef<any>();

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
    }));

    useEffect(() => {
      if (enableSearchOnEnterPress) return;
      debounceChange(onChange, value);
    }, [value, onChange, enableSearchOnEnterPress]);

    const onValueChange = (event: any) => {
      if (value !== event.target.value) {
        setValue(event.target.value);
      }
    };

    const onClearInput = () => {
      setValue("");
      if (enableSearchOnEnterPress) {
        onClear();
      }
    };

    return (
      <Wrapper
        className={className}
        isHovered={isHovered}
        isfocussed={isFocussed ? 1 : 0}
        disabled={disabled}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={(e: any) => {
          setIsFocussed(true);
          onFocus();
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        canGrow={canGrow}
      >
        <SearchIcon
          disabled={disabled}
          isfocussed={isFocussed ? 1 : 0}
          icon={solid("search")}
        />
        <InputWrapper id="search-field-input-wrapper">
          <TextInput
            data-testid="searchField"
            name={"searchField"}
            type="text"
            autoComplete="off"
            value={value}
            ref={inputRef}
            placeholder={placeholder}
            onChange={onValueChange}
            disabled={disabled}
            isfocussed={isFocussed ? 1 : 0}
            onBlur={() => {
              setTimeout(() => {
                setIsFocussed(false);
              }, 100);
            }}
            onKeyPress={(e: any) => {
              if (enableSearchOnEnterPress && e.key === "Enter") {
                onChange(value);
              }
            }}
          />
          {showResultsInside && value && found > 0 && (
            <FoundCount>{found} found</FoundCount>
          )}
          <ClearIconWrapper
            data-testid="clearButton"
            id="clearButton"
            initial={{ opacity: 0 }}
            animate={
              value ? { opacity: 1, width: "auto" } : { opacity: 0, width: 0 }
            }
            disabled={disabled}
            isfocussed={isFocussed ? 1 : 0}
            onClick={(e: any) => {
              if (value) {
                e.preventDefault();
                e.stopPropagation();
                onClearInput();
                setTimeout(() => {
                  setIsFocussed(false);
                }, 100);
              }
            }}
          >
            <ClearIcon disabled={disabled} icon={solid("times-circle")} />
          </ClearIconWrapper>
        </InputWrapper>
      </Wrapper>
    );
  }
);

export default SearchField;
