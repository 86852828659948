import InfoCard, { ValueType } from "./InfoCard";

import { Line } from "react-chartjs-2";
import styled from "styled-components";
import { getBasicChartOptions } from "helpers";
import SharecodesList from "./sharecodesInformation/SharecodesList";

const MainWrapper = styled.div``;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  column-gap: 10px;
  margin-bottom: 10px;
`;

const GraphContainer = styled.div`
  width: 100%;
  height: 400px;
  padding: 20px 20px 30px;
  border: solid 1px ${({ theme }) => theme.background100};
  background-color: ${({ theme }) => theme.white};
  box-sizing: border-box;
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: #9f9caa;
  margin-right: auto;
`;

const GraphLegend = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const LegendLabel = styled.div`
  font-size: 0.75rem;
  color: #706a81;
`;

const TotalVisitorsColor = "rgb(52, 181, 58)";
const UniqueVisitorsColor = "rgb(255, 178, 0)";

const Indicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
`;

const TotalVisitorsLegend = styled(Indicator)`
  background-color: ${TotalVisitorsColor};
`;

const UniqueVisitorsLegend = styled(Indicator)`
  background-color: ${UniqueVisitorsColor};
`;

export interface ShowcaseStatisticsProps {}

const ShowcaseStatistics = () => {
  const data = {
    labels: ["1", "2", "3", "1", "2", "3", "1", "2", "3"],
    datasets: [
      {
        type: "line" as const,
        fill: false,
        label: "Unique Visitors",
        data: [500, 200, 300, 500, 200, 300, 500, 200, 300],
        borderColor: "rgb(255, 178, 0)",
        backgroundColor: "rgba(255, 178, 0, 0.5)",
        lineTension: 0.4,
      },
      {
        type: "line" as const,
        fill: false,
        label: "Total Visitors",
        data: [700, 400, 500, 700, 400, 500, 700, 400, 500],
        borderColor: "rgb(52, 181, 58)",
        lineTension: 0.4,
      },
    ],
  };

  return (
    <MainWrapper>
      <HorizontalWrapper>
        <InfoCard title={"TOTAL VISITS"} value={248} />
        <InfoCard title={"UNIQUE VISITS"} value={124} />
        <InfoCard
          title={"TOTAL TIME SPENT"}
          value={367000}
          valueType={ValueType.time}
        />
        <InfoCard
          title={"AVERAGE TIME PER VISIT"}
          value={65000}
          valueType={ValueType.time}
        />
      </HorizontalWrapper>
      <GraphContainer>
        <GraphLegend>
          <Label>VISITS</Label>
          <TotalVisitorsLegend />
          <LegendLabel>All Visits</LegendLabel>
          <UniqueVisitorsLegend />
          <LegendLabel>Unique Visits</LegendLabel>
        </GraphLegend>
        <Line
          //@ts-ignore
          options={getBasicChartOptions(700, false)}
          data={data}
        />
      </GraphContainer>
      <SharecodesList />
    </MainWrapper>
  );
};

export default ShowcaseStatistics;
