import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

// Styling
import styled from 'styled-components';

const HorizontalWrapper = styled.div`
    max-width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    width: fit-content;
    border-radius: 10px;
    ${({ showPointer }) => showPointer ? 'cursor: pointer;' : ''}
`;

const Label = styled.label`
    margin-right: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'SF Pro Text', 'Helvetica Neue', 'Helvetica', 'Arial';
    font-size: 0.875rem;
    color: ${(props) => props.theme.fadedTextColor};
    white-space: nowrap;
    ${({ showPointer }) => showPointer ? 'cursor: pointer;' : ''}
`;

const SortByIndicator = styled(FontAwesomeIcon)`
    color: ${(props) =>
        props.current === 'true'
            ? props.theme.accent
            : props.theme.fadedTextColor};
`;

/**
 * Displays a label with an icon indicating the field is sorted ascending or descending.
 * The component can be clicked to change the state which is then returned to the parent.*/
const SortByField = ({
    label,
    fieldToSort,
    currentFieldToSort,
    onSortByChanged
}) => {
    const [isAscending, setIsAscending] = useState(true);

    useEffect(() => {
        if (fieldToSort !== currentFieldToSort) {
            setIsAscending(false);
        }
    }, [fieldToSort, currentFieldToSort]);

    return (
        <HorizontalWrapper
            data-testid="sortByField"
            showPointer={!!fieldToSort}
            selected={fieldToSort === currentFieldToSort}
            onClick={(e) => {
                if (fieldToSort) {
                    e.stopPropagation();
                    onSortByChanged(fieldToSort, !isAscending);
                    setIsAscending(!isAscending);
                }
            }}
        >
            <Label
                title={label}
                showPointer={!!fieldToSort}>
                    {label}
            </Label>
            {fieldToSort && (
                <SortByIndicator
                    current={(fieldToSort === currentFieldToSort).toString()}
                    data-testid="sortByIcon"
                    icon={isAscending ? faAngleUp : faAngleDown}
                    size="1x"
                />
            )}
        </HorizontalWrapper>
    );
};

SortByField.propTypes = {
    /** The label that will be displayed next to the sortBy indicator */
    label: string.isRequired,
    /** The field to sort on that this field changes */
    fieldToSort: string.isRequired | undefined,
    /** The field that is currently being sorted on, used to check if it's this component that is handling that field */
    currentFieldToSort: string.isRequired,
    /** Callback when the user changes the sort by option
     * @callback
     * @param {string} fieldToSort The field on which we want to apply this sorting
     * @param {bool} isAscending Whether the user wants to sort ascending, false means descending
     */
    onSortByChanged: func.isRequired
};

export default SortByField;
