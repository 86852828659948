import { QueryResult } from '../models/Node';
import { runQuery } from '../neo4jHelper';

const launchMarketplace = 1693922355000;
const marketplaceId = 'prompto';

export const Marketplace = {
  getTotalMarketplaceVisitsPerTimerange: (timeRangeList: any) => {
    return runQuery(
      `
      UNWIND $timeRangeList as range
      MATCH (p:PromptoSession {type:"marketplace"})-[:REFERS]-(mp: Marketplace {objectId: '${marketplaceId}'})
      WHERE p.createdAt >= range.startTimestamp AND p.createdAt <= range.endTimestamp AND p.createdAt >= ${launchMarketplace}
    RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp, count(p) as visits`,
      { timeRangeList }
    ).then((result: QueryResult) => {
      const parsed: Array<{
        startTimestamp: number;
        endTimestamp: number;
        visits: number;
      }> = [];

      result.records.forEach((record) => {
        const startTimestamp = Number(record._fields[0]);
        const endTimestamp = Number(record._fields[1]);
        const visits = Number(record._fields[2]);

        parsed.push({
          startTimestamp,
          endTimestamp,
          visits
        });
      });
      return parsed;
    });
  },

  getUniqueMarketplaceVisitsPerTimerange: (timeRangeList: any) => {
    return runQuery(
      `
      UNWIND $timeRangeList as range
      MATCH (v:Visitor)-[:CREATED]->(a:Action)-[:REFERS]->(p:PromptoSession {type:"marketplace"})-[:REFERS]-(mp: Marketplace {objectId: '${marketplaceId}'})
      WHERE p.createdAt >= range.startTimestamp AND p.createdAt <= range.endTimestamp AND p.createdAt >= ${launchMarketplace}
    RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp, count(distinct v) as visits`,
      { timeRangeList }
    ).then((result: QueryResult) => {
      const parsed: Array<{
        startTimestamp: number;
        endTimestamp: number;
        visits: number;
      }> = [];

      result.records.forEach((record) => {
        const startTimestamp = Number(record._fields[0]);
        const endTimestamp = Number(record._fields[1]);
        const visits = Number(record._fields[2]);

        parsed.push({
          startTimestamp,
          endTimestamp,
          visits
        });
      });
      return parsed;
    });
  },

  getTotalVisitsPerProjectPerTimerange: (timeRangeList: any) => {
    const query = `
    UNWIND $timeRangeList as range
    MATCH (a:Action {name: "marketplaceProjectVisited"})-[:REFERS]->(mp:MarketplaceProject {state:"active"})-[:REFERS]->(p:Project)
    MATCH (mp)-[:BELONGS_TO]-(m:Marketplace {objectId:'${marketplaceId}'})
    WHERE a.createdAt >= range.startTimestamp AND a.createdAt <= range.endTimestamp AND a.createdAt >= ${launchMarketplace}
  RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp, count(a), p.title`;

    return runQuery(query, { timeRangeList }).then((result: QueryResult) => {
      const parsed: Array<{
        startTimestamp: number;
        endTimestamp: number;
        count: number;
        projectTitle: string;
      }> = [];

      result.records.forEach((record) => {
        const startTimestamp = Number(record._fields[0]);
        const endTimestamp = Number(record._fields[1]);
        const count = Number(record._fields[2]);
        const projectTitle = record._fields[3].toString();

        parsed.push({
          startTimestamp,
          endTimestamp,
          projectTitle,
          count
        });
      });
      return parsed;
    });
  },

  getUniqueVisitsPerProjectPerTimerange: (timeRangeList: any) => {
    return runQuery(
      `
      UNWIND $timeRangeList as range
      MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "marketplaceProjectVisited"})-[:REFERS]->(mp:MarketplaceProject {state:"active"})-[:REFERS]->(p:Project)
      MATCH (mp)-[:BELONGS_TO]-(m: Marketplace {objectId: '${marketplaceId}'})
      WHERE a.createdAt >= range.startTimestamp AND a.createdAt <= range.endTimestamp AND a.createdAt >= ${launchMarketplace}
    RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp,  count(distinct v) as visits, p.title`,
      { timeRangeList }
    ).then((result: QueryResult) => {
      const parsed: Array<{
        startTimestamp: number;
        endTimestamp: number;
        count: number;
        projectTitle: string;
      }> = [];

      result.records.forEach((record) => {
        const startTimestamp = Number(record._fields[0]);
        const endTimestamp = Number(record._fields[1]);
        const count = Number(record._fields[2]);
        const projectTitle = record._fields[3].toString();

        parsed.push({
          startTimestamp,
          endTimestamp,
          projectTitle,
          count
        });
      });
      return parsed;
    });
  },

  getTotalMarketplaceVisitsInLast30Days: () => {
    const startOfDay = new Date();
    startOfDay.setUTCHours(0, 0, 0, 0);
    const timestamp = startOfDay.getTime() + 86400000 - 2629743000;

    return runQuery(
      `MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type:"marketplace"})-[:REFERS]-(mp: Marketplace {objectId: '${marketplaceId}'}) WHERE a.createdAt >= ${timestamp} AND a.createdAt >= ${launchMarketplace} RETURN count(v)`
    ).then((result: QueryResult) => {
      return result.records[0]._fields[0];
    });
  },

  getTotalMarketplaceVisits: () => {
    return runQuery(
      `MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type:"marketplace"})-[:REFERS]-(mp: Marketplace {objectId: '${marketplaceId}'}) WHERE a.createdAt >= ${launchMarketplace} RETURN count(a)`
    ).then((result: QueryResult) => {
      return result.records[0]._fields[0];
    });
  },

  getUniqueMarketplaceVisitsInLast30Days: () => {
    const startOfDay = new Date();
    startOfDay.setUTCHours(0, 0, 0, 0);
    const timestamp = startOfDay.getTime() + 86400000 - 2629743000;

    return runQuery(
      `MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type:"marketplace"})-[:REFERS]-(mp: Marketplace {objectId: '${marketplaceId}'}) WHERE a.createdAt >= ${timestamp} AND a.createdAt >= ${launchMarketplace} RETURN  count(distinct v) as visits`
    ).then((result: QueryResult) => {
      return result.records[0]._fields[0];
    });
  },

  getTotalUniqueMarketplaceVisits: () => {
    return runQuery(
      `MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "startPromptoSession"})-[:REFERS]->(p:PromptoSession {type:"marketplace"})-[:REFERS]-(mp: Marketplace {objectId: '${marketplaceId}'}) WHERE a.createdAt >= ${launchMarketplace} RETURN  count(distinct v) as visits`
    ).then((result: QueryResult) => {
      return result.records[0]._fields[0];
    });
  },

  getTotalVisitsPerProjectInLast30Days: () => {
    const startOfDay = new Date();
    startOfDay.setUTCHours(0, 0, 0, 0);
    const timestamp = startOfDay.getTime() + 86400000 - 2629743000;

    const query = `  MATCH (a:Action {name: "marketplaceProjectVisited"})-[:REFERS]->(mp:MarketplaceProject {state:"active"})-[:REFERS]->(p:Project)
    MATCH (mp)-[:BELONGS_TO]-(m: Marketplace {objectId: '${marketplaceId}'})
    WHERE a.createdAt >= ${timestamp} AND a.createdAt >= ${launchMarketplace} RETURN count(a), p.title`;

    return runQuery(query).then((result: QueryResult) => {
      const parsed: Array<{
        count: number;
        projectTitle: string;
      }> = [];

      result.records.forEach((record) => {
        const count = Number(record._fields[0]);
        const projectTitle = record._fields[1].toString();

        parsed.push({
          projectTitle,
          count
        });
      });

      return parsed;
    });
  },

  getUniqueVisitsPerProjectInLast30Days: () => {
    const startOfDay = new Date();
    startOfDay.setUTCHours(0, 0, 0, 0);
    const timestamp = startOfDay.getTime() + 86400000 - 2629743000;

    return runQuery(
      `MATCH (v:Visitor)-[:CREATED]->(a:Action {name: "marketplaceProjectVisited"})-[:REFERS]->(mp:MarketplaceProject {state:"active"})-[:REFERS]->(p:Project)
      MATCH (mp)-[:BELONGS_TO]-(m: Marketplace {objectId: '${marketplaceId}'})
      WHERE a.createdAt >= ${timestamp} AND a.createdAt >= ${launchMarketplace} RETURN count(a), p.title, v`
    ).then((result: QueryResult) => {
      const parsed: Array<{
        count: number;
        projectTitle: string;
      }> = [];

      result.records.forEach((record) => {
        const projectTitle = record._fields[1].toString();

        const matchedProject = parsed.find(
          (x: any) => x.projectTitle === projectTitle
        );
        if (matchedProject) {
          matchedProject.count += 1;
        } else {
          parsed.push({
            projectTitle,
            count: 1
          });
        }
      });

      return parsed;
    });
  },

  getTotalLeadsGeneratedPerProject: () => {
    return runQuery(
      `MATCH (l:Lead {source:"marketplace", state:"active"})-[:REFERS]->(mp:MarketplaceProject)
      MATCH (mp)-[:BELONGS_TO]-(m: Marketplace {objectId: '${marketplaceId}'})
      MATCH (mp)-[:REFERS]->(p:Project)-[:BELONGS_TO]->(v:Vault)
      WHERE l.createdAt >= ${launchMarketplace}
      RETURN l, p.title, v, p.objectId`
    ).then((result: QueryResult) => {
      const parsed: Array<{
        lead: any;
        projectTitle: string;
        projectObjectId: string;
        vault: any;
      }> = [];

      result.records.forEach((record) => {
        const lead = { ...record._fields[0].properties };
        const projectTitle = record._fields[1].toString();
        const vault = { ...record._fields[2].properties };
        const projectObjectId = record._fields[3].toString();

        parsed.push({
          projectTitle,
          projectObjectId,
          lead,
          vault
        });
      });

      return parsed;
    });
  },

  getTotalLeadsGeneratedPerProjectThisMonth: (timestamps: any) => {
    const query = `MATCH (l:Lead {source:"marketplace", state:"active"})-[:BELONGS_TO]->(p:Project)<-[:REFERS]-(mp:MarketplaceProject)
    MATCH (mp)-[:BELONGS_TO]-(m: Marketplace {objectId: '${marketplaceId}'})
    MATCH (p)-[:BELONGS_TO]->(v:Vault)
    WHERE l.createdAt >= ${timestamps.firstDay.getTime()} AND l.createdAt <= ${timestamps.lastDay.getTime()}
    OPTIONAL MATCH (l)-[:REFERS]->(c:ContactReason {objectId:"askToScheduleVisit"})
    OPTIONAL MATCH (l)-[:REFERS]->(cr:ContactReason {objectId:"askForMoreInfo"})
    RETURN l, p.title, v, p.objectId,
    CASE WHEN c IS NOT NULL THEN 1 ELSE 0 END AS hasAskedToScheduleVisit,
    CASE WHEN cr IS NOT NULL THEN 1 ELSE 0 END AS hasAskedForMoreInfo
    `;

    return runQuery(query).then((result: QueryResult) => {
      const parsed: Array<{
        lead: any;
        projectTitle: string;
        projectId: string;
        vault: any;
      }> = [];

      result.records.forEach((record) => {
        const lead = { ...record._fields[0].properties };
        const projectTitle = record._fields[1].toString();
        const vault = { ...record._fields[2].properties };
        const projectId = record._fields[3].toString();
        const hasAskedToScheduleVisit =
          Number(record._fields[4]) === 1 ? true : false;
        const hasAskedForMoreInfo =
          Number(record._fields[5]) === 1 ? true : false;

        lead.hasAskedToScheduleVisit = hasAskedToScheduleVisit;
        lead.hasAskedForMoreInfo = hasAskedForMoreInfo;

        parsed.push({
          projectTitle,
          projectId,
          lead,
          vault
        });
      });

      return parsed;
    });
  },

  getTotalHomepageVisitorsPerTimerange: (timeRangeList: any) => {
    return runQuery(
      `
      UNWIND $timeRangeList as range
      MATCH (v:Visitor)-[:CREATED]->(a:Action {name:"trackMarketplaceHomePageVisitor"})-[:REFERS]->(p:PromptoSession)-[:REFERS]-(m: Marketplace {objectId: '${marketplaceId}'})
      WHERE p.createdAt >= range.startTimestamp AND p.createdAt <= range.endTimestamp AND p.createdAt >= ${launchMarketplace}
      RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp, count(DISTINCT p) as visits`,
      { timeRangeList }
    ).then((result: QueryResult) => {
      const parsed: Array<{
        startTimestamp: number;
        endTimestamp: number;
        visits: number;
      }> = [];

      result.records.forEach((record) => {
        const startTimestamp = Number(record._fields[0]);
        const endTimestamp = Number(record._fields[1]);
        const visits = Number(record._fields[2]);

        parsed.push({
          startTimestamp,
          endTimestamp,
          visits
        });
      });

      return parsed;
    });
  },

  getTotalProjectsVisitorsPerTimerange: (timeRangeList: any) => {
    return runQuery(
      `
      UNWIND $timeRangeList as range
      MATCH (v:Visitor)-[:CREATED]->(a:Action {name:"trackMarketplaceProjectsVisitor"})-[:REFERS]->(p:PromptoSession)-[:REFERS]-(m: Marketplace {objectId: '${marketplaceId}'})
      WHERE p.createdAt >= range.startTimestamp AND p.createdAt <= range.endTimestamp AND p.createdAt >= ${launchMarketplace}
      RETURN range.startTimestamp as startTimestamp, range.endTimestamp as endTimestamp, count(DISTINCT p) as visits`,
      { timeRangeList }
    ).then((result: QueryResult) => {
      const parsed: Array<{
        startTimestamp: number;
        endTimestamp: number;
        visits: number;
      }> = [];

      result.records.forEach((record) => {
        const startTimestamp = Number(record._fields[0]);
        const endTimestamp = Number(record._fields[1]);
        const visits = Number(record._fields[2]);

        parsed.push({
          startTimestamp,
          endTimestamp,
          visits
        });
      });

      return parsed;
    });
  },

  getTotalMarketplaceAccounts: () => {
    return runQuery(
      `MATCH (u:User)-[:BELONGS_TO]->(v:Vault {vaultLabel:"promptoMarketplace"})
RETURN count(u)`
    ).then((result: QueryResult) => {
      return Number(result.records[0]._fields[0]);
    });
  },

  getUTMsForThisMonth: () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const timestamp = firstDay.getTime();
    return runQuery(
      `MATCH (l:Lead {source:"marketplace", state:"active"})
MATCH (l)-[:BELONGS_TO]->(p:Project)-[:BELONGS_TO]->(v:Vault)
WHERE l.createdAt >= ${timestamp}
RETURN l.utmSource, count(l.utmSource) ORDER by count(l.utmSource) DESC`
    ).then((result: QueryResult) => {
      return result;
    });
  }
};
