// Styling
import styled from "styled-components";

import { Chart } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { getBasicChartOptions } from "helpers";
import { buildLabelsAndTimestampsForLast30Days } from "../helpers/DashboardHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ChartDataLabels,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Filler,
  Legend
);

const MainWrapper = styled.div`
  background: ${({ theme }) => theme.background};
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  padding: 15px;
  flex-grow: 1;
`;

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
`;

const VerticalWrapper = styled.div`
  flex-basis: 20%;
  display: flex;
  flex-flow: column;
  margin-right: 15px;
`;

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.mainTextColor};
`;

const InfoCard = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
`;

const TotalUsers = styled(InfoCard)`
  background: ${({ theme }) => theme.background100};
`;
const ActiveInLast30 = styled(InfoCard)`
  background: ${({ theme }) => theme.background200};
`;
const ActiveUsers = styled(InfoCard)`
  background: linear-gradient(
    90deg,
    rgba(21, 171, 144, 0.6),
    rgba(249, 239, 209, 0.6)
  );
`;

const InfoCardLabel = styled.div`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.mainTextColor};
`;

const InfoCardValue = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.boldTextColor};
`;

const GraphContainer = styled.div`
  width: 80%;
`;

export interface ShowcaseUsersDashboardProps {
  className?: string;
  showcaseVisitorsPerDay: Array<any>;
  uniqueShowcaseVisitorsPerDay: Array<any>;
  uniqueVisitorsInLast30Days: Array<any>;
  title: string;
  infoCard1Title: string;
  infoCard2Title: string;
  infoCard3Title: string;
}

const ShowcaseUsersDashboard = ({
  className,
  showcaseVisitorsPerDay,
  uniqueShowcaseVisitorsPerDay,
  uniqueVisitorsInLast30Days,
  title,
  infoCard1Title,
  infoCard2Title,
  infoCard3Title,
}: ShowcaseUsersDashboardProps) => {
  const labels = buildLabelsAndTimestampsForLast30Days();

  let biggestValue = 0;
  if (showcaseVisitorsPerDay) {
    showcaseVisitorsPerDay.forEach((x) => {
      if (x.count > biggestValue) {
        biggestValue = x.count;
      }
    });
  }

  let totalVisitsData = [];
  if (showcaseVisitorsPerDay && labels) {
    totalVisitsData = labels.map((x) => {
      const matchedRow = showcaseVisitorsPerDay.find((y) => {
        return (
          y.startTimestamp === x.startTimestamp &&
          y.endTimestamp === x.endTimestamp
        );
      });

      return matchedRow ? matchedRow.count : 0;
    });
  }

  let uniqueVisitsData = [];
  if (uniqueShowcaseVisitorsPerDay && labels) {
    uniqueVisitsData = labels.map((x) => {
      const matchedRow = uniqueShowcaseVisitorsPerDay.find((y) => {
        return (
          y.startTimestamp === x.startTimestamp &&
          y.endTimestamp === x.endTimestamp
        );
      });

      return matchedRow ? matchedRow.count : 0;
    });
  }

  const data = {
    labels: labels
      .map((x) => {
        return x.label;
      })
      .reverse(),
    datasets: [
      {
        type: "line" as const,
        fill: true,
        label: "Unique Visitors",
        data: uniqueVisitsData.reverse(),
        borderColor: "rgb(21, 171, 144,0.5)",
        backgroundColor: "rgb(21, 171, 144,0.5)",
      },
      {
        type: "line" as const,
        fill: true,
        label: "Total Visitors",
        data: totalVisitsData.reverse(),
        borderColor: "rgb(199, 202, 209,0.5)",
        backgroundColor: "rgb(199, 202, 209,0.5)",
      },
      {
        type: "bar" as const,
        fill: true,
        data: labels.reverse().map((x: any) => {
          if (x.isWeekend) return 500000;
          return "";
        }),
        barPercentage: 1.3,
        borderColor: "rgb(241, 242, 243,1)",
        backgroundColor: "rgb(241, 242, 243,1)",
      },
    ],
  };

  return (
    <MainWrapper className={className}>
      <Title>{title}</Title>
      <HorizontalWrapper>
        <VerticalWrapper>
          <TotalUsers>
            <InfoCardLabel>{infoCard1Title}</InfoCardLabel>
            <InfoCardValue>{uniqueVisitorsInLast30Days}</InfoCardValue>
          </TotalUsers>
          <ActiveInLast30>
            <InfoCardLabel>{infoCard2Title}</InfoCardLabel>
            <InfoCardValue>{totalVisitsData[29]}</InfoCardValue>
          </ActiveInLast30>
          <ActiveUsers>
            <InfoCardLabel>{infoCard3Title}</InfoCardLabel>
            <InfoCardValue>{uniqueVisitsData[29]}</InfoCardValue>
          </ActiveUsers>
        </VerticalWrapper>
        <GraphContainer>
          <Chart
            type="bar"
            //@ts-ignore
            options={getBasicChartOptions(biggestValue)}
            data={data}
          />
        </GraphContainer>
      </HorizontalWrapper>
    </MainWrapper>
  );
};

export default ShowcaseUsersDashboard;
