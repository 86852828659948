import { useDataState } from "stores/DataStore";

// Styling
import styled, { StyledProps } from "styled-components";
import { useEffect, useState } from "react";
import { searchEntities, sortEntities } from "helpers";
import SearchField from "components/SearchField";
import SortByField from "components/SortByField";
import PaginationControls from "components/PaginationControls";
import ShowcaseInfoCard from "./ShowcaseInfoCard";
import { Project } from "helpers/neo4j/models/GProject";
import { useHistory } from "react-router";

const SPACING = 30;

const MainWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-top: ${SPACING - 10}px;
  height: fit-content;
  align-items: center;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const Title = styled.h1`
  margin-right: 50px;
`;

const ListBackground = styled.div`
  background: ${({ theme }) => theme.background};
  padding: 16px 32px 8px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const NavBarWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const TableHeader = styled.div<StyledProps<{ columnTemplate: string }>>`
  display: grid;
  grid-template-columns: ${({ columnTemplate }) => columnTemplate};
  grid-template-rows: 40px;
  padding-left: 10px;
  box-shadow: inset 0 -1px 0 0 #e3e5e8;
`;

const ShowcaseList = () => {
  const history = useHistory();

  const { DataState } = useDataState();
  const { allProjectsWithVisitors, allProjectsWithVisitDuration } = DataState;

  const [perPage, setPerPage] = useState({ label: 10, value: 10 });
  const [currentStart, setCurrentStart] = useState(0);

  const [sortedField, setSortedField] = useState("lastActivity");
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const [dataList, setDataList] = useState<Array<any>>([]);
  const [filteredList, setFilteredList] = useState<Array<any>>([]);

  const headers = [
    "projectName",
    "company",
    "created",
    "lastActivity",
    "totalVisits",
    "uniqueVisits",
    "averageTimeSpent",
    "totalTimeSpent",
  ];
  const columnTemplate = "20% 20% 10% 10% 10% 10% 10% 10%";

  useEffect(() => {
    if (
      allProjectsWithVisitDuration?.length > 0 &&
      allProjectsWithVisitors?.length > 0
    ) {
      const mergedList: Array<any> = [];

      allProjectsWithVisitDuration.forEach((project: any) => {
        let totalTimeSpent = 0;
        project.sessions.forEach((session: any) => {
          if (!session.properties.endSession) {
            // We only start tracking after user is 10 seconds on platform,
            // so let's use an average of 5 seconds when user is less than 10 seconds on the platform (in ms)
            totalTimeSpent += 5000;
          } else {
            const timeForSession =
              session.properties.endSession - session.properties.createdAt;
            totalTimeSpent += timeForSession;
          }
        });

        mergedList.push({
          ...project,
          ...allProjectsWithVisitors.find(
            (x: any) => x.objectId === project.objectId
          ),
          totalTimeSpent,
        });
      });

      setDataList(mergedList);
    }
  }, [allProjectsWithVisitors, allProjectsWithVisitDuration]);

  useEffect(() => {
    if (dataList?.length > 0) {
      let filteredL = sortEntities(dataList, sortedField, sortAscending, false);

      if (searchValue) {
        filteredL = searchEntities(searchValue, filteredL);
      }

      setFilteredList([...filteredL]);
    }
  }, [dataList, sortAscending, sortedField, searchValue]);

  const openProject = (project: Project) => {
    history.push(`/project/${project.objectId}`);
  };

  return (
    <MainWrapper>
      <VerticalWrapper>
        <HorizontalWrapper>
          <Title>Showcases</Title>
          <SearchField
            onChange={(e: string) => {
              setSearchValue(e);
            }}
            placeholder="search"
            found={0}
            showResultsInside={true}
            canGrow={false}
            enableSearchOnEnterPress={false}
            onClear={() => {}}
            onFocus={() => {}}
          />
        </HorizontalWrapper>
        <ListBackground>
          <TableHeader columnTemplate={columnTemplate}>
            {headers.map((header, index) => {
              return (
                <SortByField
                  key={`sortfield${index}`}
                  // @ts-ignore
                  label={header}
                  fieldToSort={header}
                  currentFieldToSort={sortedField}
                  onSortByChanged={(sortOnField: any, isAscending: any) => {
                    setSortedField(sortOnField);
                    setSortAscending(isAscending);
                  }}
                />
              );
            })}
          </TableHeader>
          <ListWrapper>
            {filteredList.map((project: any, index: number) => {
              if (
                index >= currentStart &&
                index < currentStart + perPage.value
              ) {
                return (
                  <ShowcaseInfoCard
                    key={`userCard${index}`}
                    project={project}
                    columnTemplate={columnTemplate}
                    onClick={() => {
                      openProject(project);
                    }}
                  />
                );
              }

              return <></>;
            })}
          </ListWrapper>
          <NavBarWrapper>
            <PaginationControls
              perPage={perPage}
              currentPage={currentStart}
              totalEntries={filteredList?.length ?? 0}
              changePerPage={(e: any) => {
                setPerPage(e);
                setCurrentStart(0);
                // if (onPerPageChange)
                //     onPerPageChange(e?.value);
              }}
              toPreviousPage={() => {
                if (currentStart > 0) {
                  setCurrentStart(
                    Math.min(Math.max(currentStart - perPage.value, 0), 1000)
                  );
                }
              }}
              toNextPage={() => {
                if (currentStart + perPage.value < filteredList.length) {
                  setCurrentStart(currentStart + perPage.value);
                }
              }}
            />
          </NavBarWrapper>
        </ListBackground>
      </VerticalWrapper>
    </MainWrapper>
  );
};

export default ShowcaseList;
