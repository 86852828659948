import { removeAllPromptoUsers } from 'helpers';
import { useReducer, createContext, useContext } from 'react';

export const Context = createContext();

const DataStore = (props) => {
  const [DataState, DataStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'addDataCollectionJob':
          return {
            ...state,
            jobList: [...state.jobList, ...action.payload]
          };
        case 'startNewJob': {
          if (state.jobList.length > 0) {
            console.log(
              'start job : ' + state.jobList.length + ' jobs remaining'
            );
            const nextJob = state.jobList[0];
            if (nextJob) {
              return {
                ...state,
                activeJobs: [
                  ...state.activeJobs.filter((x) => x.jobId !== nextJob.jobId),
                  nextJob
                ],
                jobList: [
                  ...state.jobList.filter((x) => {
                    return x.jobId !== nextJob.jobId;
                  })
                ]
              };
            }
            return state;
          }

          return state;
        }
        case 'markJobInProgress': {
          return {
            ...state,
            activeJobs: [
              ...state.activeJobs.filter(
                (x) => x.jobId !== action.payload.jobId
              ),
              { ...action.payload, inProgress: true }
            ]
          };
        }
        case 'finishJob': {
          return {
            ...state,
            activeJobs: [
              ...state.activeJobs.filter(
                (x) => x.jobId !== action.payload.jobId
              )
            ]
          };
        }
        case 'vaults':
          return {
            ...state,
            vaults: action.payload
          };
        case 'users':
          return {
            ...state,
            users: action.payload
          };
        case 'usersWithLatestActivity':
          const usersWithLatestActivity = removeAllPromptoUsers(action.payload);
          return {
            ...state,
            usersWithLatestActivity: usersWithLatestActivity
          };
        case 'usersWithActionsPerDay': {
          const usersWithActionsPerDay = action.payload.map((x) => {
            return { ...x, users: removeAllPromptoUsers(x.users) };
          });

          let newState = { ...state };
          newState.usersWithActionsPerDay = usersWithActionsPerDay;

          return newState;
        }
        case 'showcaseVisitorsForTimeRange': {
          let newState = { ...state };
          newState.showcaseVisitorsPerDay = action.payload;

          return newState;
        }
        case 'uniqueShowcaseVisitorsForTimeRange': {
          console.log(action);
          let newState = { ...state };
          newState.uniqueShowcaseVisitorsPerDay = action.payload;

          return newState;
        }
        case 'allProjects':
          return {
            ...state,
            projects: action.payload
          };
        case 'totalUnitAmount':
          return {
            ...state,
            totalUnitAmount: action.payload
          };
        case 'totalActiveUnitAmount':
          return {
            ...state,
            totalActiveUnitAmount: action.payload
          };
        case 'totalShowcaseVisitors':
          return {
            ...state,
            totalShowcaseVisitors: action.payload
          };
        case 'totalShowcaseSharecodes':
          return {
            ...state,
            totalShowcaseSharecodes: action.payload
          };
        case 'uniqueVisitorsInLast30Days':
          return {
            ...state,
            uniqueVisitorsInLast30Days: action.payload
          };
        case 'allProjectsWithVisitors':
          return {
            ...state,
            allProjectsWithVisitors: action.payload
          };
        case 'allProjectsWithVisitDuration':
          return {
            ...state,
            allProjectsWithVisitDuration: action.payload
          };

        case 'setTotalMarketplaceVisitsPerTimerange': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              totalMarketplaceVisitsPerTimerange: action.payload
            }
          };
        }
        case 'setUniqueMarketplaceVisitsPerTimerange': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              uniqueMarketplaceVisitsPerTimerange: action.payload
            }
          };
        }
        case 'setMarketplaceProjectVisits': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              marketplaceProjectVisits: action.payload
            }
          };
        }
        case 'setUniqueVisitsPerProjectPerTimerange': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              uniqueVisitsPerProjectPerTimerange: action.payload
            }
          };
        }
        case 'setTotalMarketplaceVisits': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              totalMarketplaceVisits: action.payload
            }
          };
        }
        case 'setTotalUniqueMarketplaceVisits': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              totalUniqueMarketplaceVisits: action.payload
            }
          };
        }
        case 'setTotalMarketplaceVisitsInLast30Days': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              totalMarketplaceVisitsInLast30Days: action.payload
            }
          };
        }
        case 'setUniqueMarketplaceVisitsInLast30Days': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              uniqueMarketplaceVisitsInLast30Days: action.payload
            }
          };
        }
        case 'setTotalLeadsGeneratedPerProject': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              totalLeadsGeneratedPerProject: action.payload
            }
          };
        }
        case 'setTotalVisitsPerProjectInLast30Days': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              totalVisitsPerProjectInLast30Days: action.payload
            }
          };
        }
        case 'setUniqueVisitsPerProjectInLast30Days': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              uniqueVisitsPerProjectInLast30Days: action.payload
            }
          };
        }
        case 'setTotalHomepageVisitorsPerTimerange': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              totalHomepageVisitorsPerTimerange: action.payload
            }
          };
        }
        case 'setTotalProjectsVisitorsPerTimerange': {
          return {
            ...state,
            marketplace: {
              ...state.marketplace,
              totalProjectsVisitorsPerTimerange: action.payload
            }
          };
        }
        case 'setUnitStatusUpdates': {
          return {
            ...state,
            unitsSold: action.payload
          };
        }
        case 'setTotalMarketplaceAccounts': {
          return {
            ...state,
            totalMarketplaceAccounts: action.payload
          };
        }
        default:
          return state;
      }
    },
    {
      activeJobs: [],
      jobList: [],
      vaults: [],
      users: [],
      projects: [],
      unitsSold: [],
      totalUnitAmount: 0,
      totalActiveUnitAmount: 0,
      totalShowcaseVisitors: 0,
      totalShowcaseSharecodes: 0,
      uniqueVisitorsInLast30Days: 0,
      totalMarketplaceAccounts: 0,
      usersWithActionsPerDay: [],
      usersWithLatestActivity: [],
      showcaseVisitorsPerDay: [],
      uniqueShowcaseVisitorsPerDay: [],
      allProjectsWithVisitors: [],
      allProjectsWithVisitDuration: [],
      marketplace: {
        totalMarketplaceVisitsPerTimerange: [],
        uniqueMarketplaceVisitsPerTimerange: [],
        marketplaceProjectVisits: [],
        uniqueVisitsPerProjectPerTimerange: [],
        totalMarketplaceVisitsInLast30Days: 0,
        uniqueMarketplaceVisitsInLast30Days: 0,
        totalMarketplaceVisits: 0,
        totalUniqueMarketplaceVisits: 0,
        totalLeadsGeneratedPerProject: [],
        totalVisitsPerProjectInLast30Days: [],
        uniqueVisitsPerProjectInLast30Days: [],
        totalHomepageVisitorsPerTimerange: [],
        totalProjectsVisitorsPerTimerange: []
      }
    }
  );

  return (
    <Context.Provider value={{ DataState, DataStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useDataState = () => useContext(Context);

export default DataStore;
