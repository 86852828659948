import React, { useReducer, createContext, useContext } from "react";

import Cookies from "universal-cookie";

export const Context = createContext();

const AuthStore = (props) => {
  const cookies = new Cookies();

  const [AuthState, AuthStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case "login":
          cookies.set("sessionToken", action.payload.sessionToken, {
            path: "/",
            maxAge: 600000,
          });

          return {
            ...state,
            user: action.payload.user,
            sessionToken: action.payload.sessionToken,
            isAdmin: action.payload.isAdmin,
          };
        case "logout":
          return {
            user: null,
            sessionToken: "",
          };
        case "storageToken":
          cookies.set("storageToken", action.payload, {
            path: "/",
            maxAge: 600000,
          });
          return {
            ...state,
            storageToken: action.payload,
          };
        default:
          return state;
      }
    },
    {
      user: null,
      sessionToken: props.storedSessionToken,
      storageToken: props.storedStorageToken,
      isAdmin: false,
    }
  );
  return (
    <Context.Provider value={{ AuthState, AuthStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useAuthState = () => useContext(Context);

export default AuthStore;
