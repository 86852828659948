import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getAllMetaCampaigns, getInsightsForMetaCampaign } from 'api';
import { Marketplace } from 'helpers/neo4j/neo4jHelper';
import { dateToDDMMYY_HHMM } from 'helpers';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 15px;
  height: calc(100% - 60px);
`;

const ProjectList = styled.div`
  display: flex;
  flex-flow: column;
  height: 85%;
  overflow: auto;
`;

const UTMList = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 50px;
`;

const ProjectCard = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid grey;
  border-bottom: none;
`;

const UTMCard = styled.div`
  width: 200px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border: 1px solid grey;
  padding: 4px 20px;
  margin-bottom: 12px;
`;

const UTMCardItem = styled.div``;

const CardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color ?? 'white'};

  padding: 10px;
`;

const Header = styled(ProjectCard)`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  border: none;
`;

const HeaderItem = styled(CardItem)`
  user-select: none;
  cursor: pointer;
`;

const SubHeader = styled(ProjectCard)`
  font-size: 1rem;
  margin-bottom: 5px;
  border: none;
  padding: 5px;
`;

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

const CurrentRange = styled.h3`
  margin-right: 15px;
`;

const DropDownArrow = styled.div`
  position: relative;
  user-select: none;
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 30px;
  right: 5px;
  background: #ffffff;
  border: 1px solid black;
`;

const DropDownItem = styled.div`
  padding: 5px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background: gray;
  }
`;

const LastFetchedIndicator = styled.div`
  margin-left: 50px;
`;

const TabOption = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;

enum dataPresets {
  today,
  yesterday,
  this_month,
  last_month,
  this_quarter,
  last_3d,
  last_7d,
  last_14d,
  last_30d,
  last_90d,
  last_quarter,
  last_year,
  this_year
}

const getFirstDayOfQuarter = (date: any) =>
  new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 1);
const getLastDayOfQuarter = (date: any) => {
  const start = getFirstDayOfQuarter(date);
  return new Date(
    start.getFullYear(),
    start.getMonth() + 3,
    0,
    23,
    59,
    59,
    999
  );
};

// Helper to remove time part of a date
const startOfDay = (date: any) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());
const endOfDay = (date: any) =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  );

const lastNDays = (days: any, now: any) => {
  const firstDay = endOfDay(now);
  const lastDay = startOfDay(new Date(now.setDate(now.getDate() - days)));
  return { firstDay, lastDay };
};

const LeadsReportingPage = () => {
  const [initialized, setInitialized] = useState<boolean>(false);

  // Meta
  const [metaCampaigns, setMetaCampaigns] = useState<any>([]);
  const [selectedDataPreset, setSelectedDataPreset] = useState<any>(
    dataPresets[2]
  );
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  // Graph
  const [allLeads, setAllLeads] = useState<any>([]);
  const [allProjects, setAllProjects] = useState<any>([]);

  // Data
  const [sortedField, setSortedField] = useState<string>('title');
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const [dataLastFetched, setDataLastFetched] = useState<any>();
  const [allProjectsWithData, setAllProjectsWithData] = useState<any>([]);
  const [sortedProjectsWithData, setSortedProjectsWithData] = useState<any>([]);

  const [activeTab, setActiveTab] = useState<string>('campaigns');
  const [allUTMs, setAllUTMs] = useState<any>([]);

  const callInterval = useRef<any>();

  const getColorForCard = (value: number) => {
    // Clamping the value between -100 and 100
    value = Math.max(-100, Math.min(100, value));

    const maxColorIntensity = 200;
    const minColorIntensity = 30;

    let red,
      green,
      blue = 0; // Initialize red and green, and set blue to 0 since it's unused

    if (value < 0) {
      // Calculate the intensity of red for negative values
      // Start from a visible minimum intensity and increase with the magnitude of the value
      red =
        minColorIntensity +
        Math.floor((-value / 100) * (maxColorIntensity - minColorIntensity));
      green = 0; // No green if the value is negative
    } else if (value > 0) {
      // Calculate the intensity of green for positive values
      // Start from a visible minimum intensity and increase with the magnitude of the value
      green =
        minColorIntensity +
        Math.floor((value / 100) * (maxColorIntensity - minColorIntensity));
      red = 0; // No red if the value is positive
    } else {
      // Neutral color for zero
      red = green = blue = 0;
    }

    // Return the CSS color string
    return `rgba(${red}, ${green}, ${blue},0.5)`;
  };

  const getColorForCost = (value: number) => {
    if (value > 45) {
      return `rgba(255,0,0,0.5)`;
    } else if (value >= 25 && value <= 45) {
      return `rgba(255,155,0,0.5)`;
    } else {
      return `rgba(0,255,0,0.5)`;
    }
  };

  const getGraphTimerangesForMetaRange = useCallback(() => {
    const now = new Date();
    switch (selectedDataPreset) {
      case 'today': {
        const firstDay = startOfDay(now);
        const lastDay = endOfDay(now);

        return { firstDay, lastDay };
      }
      case 'yesterday': {
        const yesterdayDate = new Date(now.setDate(now.getDate() - 1));
        const firstDay = startOfDay(yesterdayDate);
        const lastDay = endOfDay(yesterdayDate);

        return { firstDay, lastDay };
      }
      case 'this_month': {
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDay = new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          0,
          23,
          59,
          59,
          999
        );

        return { firstDay, lastDay };
      }
      case 'last_month': {
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);
        const firstDay = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth(),
          1
        );
        const lastDay = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth() + 1,
          0,
          23,
          59,
          59,
          999
        );

        return { firstDay, lastDay };
      }
      case 'this_quarter': {
        const firstDay = getFirstDayOfQuarter(now);
        const lastDay = getLastDayOfQuarter(now);

        return { firstDay, lastDay };
      }
      case 'last_3d': {
        return lastNDays(3, now);
      }
      case 'last_7d': {
        return lastNDays(7, now);
      }
      case 'last_14d': {
        return lastNDays(14, now);
      }
      case 'last_30d': {
        return lastNDays(30, now);
      }
      case 'last_90d': {
        return lastNDays(90, now);
      }
      case 'last_quarter': {
        const lastQuarterDate = new Date(now.getFullYear(), now.getMonth() - 3);
        const firstDay = getFirstDayOfQuarter(lastQuarterDate);
        const lastDay = getLastDayOfQuarter(lastQuarterDate);

        return { firstDay, lastDay };
      }
      case 'last_year': {
        const lastYear = now.getFullYear() - 1;
        const firstDay = new Date(lastYear, 0, 1);
        const lastDay = new Date(lastYear, 11, 31, 23, 59, 59, 999);

        return { firstDay, lastDay };
      }
      case 'this_year': {
        const firstDay = new Date(now.getFullYear(), 0, 1);
        const lastDay = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);

        return { firstDay, lastDay };
      }
    }
  }, [selectedDataPreset]);

  const fetchAllData = useCallback(() => {
    getAllMetaCampaigns().then((result: any) => {
      const filtered = result.data.data.filter((x: any) => {
        return x.name.includes('_');
      });

      const formatted = filtered.map((x: any) => {
        const firstUnderscore = x.name.indexOf('_');
        const projectId = x.name.substring(0, firstUnderscore);
        return {
          metaId: x.id,
          name: x.name.substring(firstUnderscore + 1, x.name.length),
          projectId,
          status: x.status,
          stopTime: x.stop_time
        };
      });

      setMetaCampaigns([]);
      const getInsightsPromises: any = [];

      formatted.forEach((x: any) => {
        getInsightsPromises.push(
          getInsightsForMetaCampaign(x.metaId, selectedDataPreset)
        );
      });

      Promise.all(getInsightsPromises).then((result: any) => {
        const allInsights: any = [];

        result.forEach((res: any) => {
          const { data } = res.data;

          if (data?.length > 0) {
            const matchedCampaign = formatted.find(
              (x: any) => x.metaId === data[0].campaign_id
            );

            allInsights.push({
              ...matchedCampaign,
              spend: Number(data[0].spend),
              impressions: Number(data[0].impressions)
            });
          }
        });

        setMetaCampaigns([...allInsights]);
      });
    });

    const timestampsForGraph = getGraphTimerangesForMetaRange();

    if (timestampsForGraph) {
      Marketplace.getTotalLeadsGeneratedPerProjectThisMonth(
        getGraphTimerangesForMetaRange()
      ).then((result: any) => {
        setAllLeads(result);
        const formatted: any = [];

        result.forEach((x: any) => {
          const foundProject = formatted.find(
            (y: any) => y.projectId === x.projectId
          );

          if (foundProject) {
            foundProject.leads.push(x.lead);
          } else {
            formatted.push({
              projectTitle: x.projectTitle,
              projectId: x.projectId,
              vault: x.vault,
              leads: [x.lead]
            });
          }
        });

        setAllProjects(formatted);
      });
    }

    setDataLastFetched(new Date());

    Marketplace.getUTMsForThisMonth().then((result: any) => {
      const parsed: Array<{
        source: string;
        count: number;
      }> = [];

      result.records.forEach((record: any) => {
        const source = record._fields[0];
        const count = Number(record._fields[1]);

        parsed.push({
          source,
          count
        });
      });

      setAllUTMs(parsed);
    });
  }, [selectedDataPreset, getGraphTimerangesForMetaRange]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);

      fetchAllData();

      callInterval.current = setInterval(() => {
        fetchAllData();
      }, 60000 * 5);

      return () => {
        clearInterval(callInterval.current);
      };
    }
  }, [initialized, fetchAllData]);

  // Enhancing project data
  useEffect(() => {
    if (allProjects.length > 0 && metaCampaigns?.length > 0) {
      const enhanced = allProjects.map((x: any) => {
        const matchingInsights = metaCampaigns.filter(
          (y: any) => x.projectId === y.projectId
        );

        let activeCount = 0;
        const now = new Date().getTime();
        matchingInsights.forEach((insight: any) => {
          if (insight.status === 'ACTIVE') {
            const date = new Date(insight.stopTime).getTime();
            if (date > now) {
              activeCount += 1;
            }
          }
        });

        let revenue = 0;
        x.leads.forEach((lead: any) => {
          if (lead.hasAskedToScheduleVisit) {
            revenue += 65;
          } else {
            revenue += 45;
          }
        });

        const avgRevenuePerLead = (revenue / x.leads.length).toFixed(2);
        let spend = 0;
        matchingInsights.forEach((insight: any) => {
          spend += insight.spend ?? 0;
        });
        const profit: number = Number((revenue - spend).toFixed(2));

        const costPerLead = Number((spend / x.leads.length).toFixed(2));
        const profitPerLead = Number((profit / x.leads.length).toFixed(2));

        return {
          ...x,
          status: activeCount,
          metaId: matchingInsights[0]?.metaId,
          revenue,
          avgRevenuePerLead,
          spend,
          profit,
          costPerLead,
          profitPerLead
        };
      });

      // Get campaigns that don't have any leads generated yet
      const filtered = [
        ...metaCampaigns.filter((x: any) => {
          const matchingP = allProjects.find(
            (y: any) => y.projectId === x.projectId
          );
          if (matchingP) {
            return false;
          }

          return true;
        })
      ];

      filtered.forEach((x: any) => {
        enhanced.push({
          status: 1,
          projectId: x.projectId,
          metaId: x.metaId,
          projectTitle: x.name,
          revenue: 0,
          avgRevenuePerLead: 0,
          spend: x.spend,
          profit: -x.spend,
          leads: [],
          costPerLead: 0,
          profitPerLead: 0
        });
      });

      setAllProjectsWithData([
        ...enhanced.map((x: any) => {
          return { ...x, objectId: uuidv4() };
        })
      ]);
    }
  }, [metaCampaigns, allProjects]);

  // Sorting logic
  useEffect(() => {
    if (sortedField && allProjectsWithData?.length > 0) {
      const sorted = allProjectsWithData.sort((a: any, b: any) => {
        switch (sortedField) {
          case 'default':
          case 'title':
            if (sortAscending && a.projectTitle > b.projectTitle) {
              return 1;
            }

            return -1;
          case 'status':
            if (sortAscending && a.status > b.status) {
              return 1;
            }

            return -1;
          case 'leads':
            if (sortAscending && a.leads.length > b.leads.length) {
              return 1;
            }

            return -1;
          case 'revenue':
            if (sortAscending && a.revenue > b.revenue) {
              return 1;
            }

            return -1;
          case 'avgRevenue':
            if (sortAscending && a.avgRevenuePerLead > b.avgRevenuePerLead) {
              return 1;
            }

            return -1;
          case 'spent':
            if (sortAscending && a.spend > b.spend) {
              return 1;
            }

            return -1;
          case 'profit':
            if (sortAscending && a.profit > b.profit) {
              return 1;
            }

            return -1;
          case 'costPerLead':
            if (sortAscending && a.costPerLead > b.costPerLead) {
              return 1;
            }

            return -1;
          case 'profitPerLead':
            if (sortAscending && a.profitPerLead > b.profitPerLead) {
              return 1;
            }

            return -1;
        }

        return 1;
      });

      setSortedProjectsWithData([...sorted]);
    }
  }, [sortAscending, sortedField, allProjectsWithData]);

  let totalRevenue = 0;
  let totalAvgPerLead = 0;
  let totalBudgetSpend = 0;
  let totalProfit = 0;
  let totalCostPerLead = 0;
  let totalProfitPerLead = 0;

  let profitPerRevenue = 0;
  let profitPerLeadPerRevenue = 0;
  let costPerLeadPerRevenue = 0;

  if (allProjectsWithData?.length > 0) {
    totalRevenue = allProjectsWithData.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue.revenue,
      0
    );

    totalAvgPerLead = Number((totalRevenue / allLeads.length).toFixed(2));

    totalBudgetSpend = Number(
      allProjectsWithData
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue.spend),
          0
        )
        .toFixed(2)
    );

    totalProfit = Number((totalRevenue - totalBudgetSpend).toFixed(2));

    totalCostPerLead = Number((totalBudgetSpend / allLeads.length).toFixed(2));

    totalProfitPerLead = Number((totalProfit / allLeads.length).toFixed(2));

    profitPerRevenue = Number((totalProfit / totalRevenue).toFixed(2)) * 100;
    profitPerLeadPerRevenue =
      Number((totalProfitPerLead / totalAvgPerLead).toFixed(2)) * 100;
    costPerLeadPerRevenue =
      Number((totalCostPerLead / totalAvgPerLead).toFixed(2)) * 100;
  }

  const options = Object.keys(dataPresets)
    .filter((key) => !isNaN(Number(key))) // Ensures only string keys are included
    .map((key) => dataPresets[key as keyof typeof dataPresets]);

  let content = (
    <>
      <HorizontalWrapper>
        <TabOption
          onClick={() => {
            setActiveTab('campaigns');
          }}
        >
          Meta Campaigns
        </TabOption>
        <TabOption
          onClick={() => {
            setActiveTab('utms');
          }}
        >
          UTMs
        </TabOption>
      </HorizontalWrapper>

      <HorizontalWrapper>
        <CurrentRange>{selectedDataPreset}</CurrentRange>
        <DropDownArrow
          onClick={() => {
            setMenuOpened((current) => !current);
          }}
        >
          v
          {menuOpened && (
            <DropdownMenu>
              {options.map((x: any) => {
                return (
                  <DropDownItem
                    onClick={() => {
                      setSelectedDataPreset(x);
                      setTimeout(() => {
                        fetchAllData();
                      }, 200);
                    }}
                  >
                    {x}
                  </DropDownItem>
                );
              })}
            </DropdownMenu>
          )}
        </DropDownArrow>
        {dataLastFetched && (
          <LastFetchedIndicator>
            {dateToDDMMYY_HHMM(dataLastFetched)}
          </LastFetchedIndicator>
        )}
      </HorizontalWrapper>
      <Header>
        <HeaderItem
          onClick={() => {
            if (sortedField === 'status') {
              setSortAscending((current) => !current);
            }
            setSortedField('status');
          }}
        >
          Active Campaigns
        </HeaderItem>
        <HeaderItem
          onClick={() => {
            if (sortedField === 'title') {
              setSortAscending((current) => !current);
            }
            setSortedField('title');
          }}
        >
          Title
        </HeaderItem>

        <HeaderItem
          onClick={() => {
            if (sortedField === 'leads') {
              setSortAscending((current) => !current);
            }
            setSortedField('leads');
          }}
        >
          Leads Generated
        </HeaderItem>
        <HeaderItem
          onClick={() => {
            if (sortedField === 'revenue') {
              setSortAscending((current) => !current);
            }
            setSortedField('revenue');
          }}
        >
          Revenue
        </HeaderItem>
        <HeaderItem
          onClick={() => {
            if (sortedField === 'avgRevenue') {
              setSortAscending((current) => !current);
            }
            setSortedField('avgRevenue');
          }}
        >
          Avg Revenue per lead
        </HeaderItem>
        <HeaderItem
          onClick={() => {
            if (sortedField === 'spent') {
              setSortAscending((current) => !current);
            }
            setSortedField('spent');
          }}
        >
          Budget spent
        </HeaderItem>
        <HeaderItem
          onClick={() => {
            if (sortedField === 'profit') {
              setSortAscending((current) => !current);
            }
            setSortedField('profit');
          }}
        >
          Profit
        </HeaderItem>
        <HeaderItem
          onClick={() => {
            if (sortedField === 'costPerLead') {
              setSortAscending((current) => !current);
            }
            setSortedField('costPerLead');
          }}
        >
          Cost per lead
        </HeaderItem>
        <HeaderItem
          onClick={() => {
            if (sortedField === 'profitPerLead') {
              setSortAscending((current) => !current);
            }
            setSortedField('profitPerLead');
          }}
        >
          Profit per lead
        </HeaderItem>
      </Header>
      <SubHeader>
        <CardItem></CardItem>
        <CardItem></CardItem>
        <CardItem>{allLeads.length}</CardItem>
        <CardItem>{`€ ${totalRevenue}`}</CardItem>
        <CardItem>{`€ ${totalAvgPerLead}`}</CardItem>
        <CardItem>{`€ ${totalBudgetSpend}`}</CardItem>
        <CardItem>{`€ ${totalProfit} - ${profitPerRevenue}%`}</CardItem>
        <CardItem>{`€ ${totalCostPerLead} - ${costPerLeadPerRevenue}%`}</CardItem>
        <CardItem>{`€ ${totalProfitPerLead} - ${profitPerLeadPerRevenue}%`}</CardItem>
      </SubHeader>
      <ProjectList>
        {sortedProjectsWithData.map((x: any, index: any) => {
          return (
            <ProjectCard key={x.objectId}>
              <CardItem>{x.status}</CardItem>
              <CardItem>{x.projectTitle}</CardItem>
              <CardItem>{x.leads.length}</CardItem>
              <CardItem>{`€ ${x.revenue}`}</CardItem>
              <CardItem>{`€ ${x.avgRevenuePerLead}`}</CardItem>
              <CardItem>{`€ ${Number(x.spend ?? 0).toFixed(2)}`}</CardItem>
              <CardItem
                color={getColorForCard(x.profit)}
              >{`€ ${x.profit}`}</CardItem>
              <CardItem
                color={getColorForCost(x.costPerLead)}
              >{`€ ${x.costPerLead}`}</CardItem>
              <CardItem>{`€ ${x.profitPerLead}`}</CardItem>
            </ProjectCard>
          );
        })}
      </ProjectList>
    </>
  );

  if (activeTab === 'utms') {
    content = (
      <>
        <HorizontalWrapper>
          <TabOption
            onClick={() => {
              setActiveTab('campaigns');
            }}
          >
            Meta Campaigns
          </TabOption>
          <TabOption
            onClick={() => {
              setActiveTab('utms');
            }}
          >
            UTMs
          </TabOption>
        </HorizontalWrapper>
        <UTMList>
          {allUTMs.map((x: any, index: any) => {
            return (
              <UTMCard key={`utm${index}`}>
                <UTMCardItem>{x.source}</UTMCardItem>
                <UTMCardItem>{x.count}</UTMCardItem>
              </UTMCard>
            );
          })}
        </UTMList>
      </>
    );
  }

  return <Wrapper>{content}</Wrapper>;
};

export default LeadsReportingPage;
