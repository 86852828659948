import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./routes/Routes";

import { ThemeProvider } from "styled-components";

const darkColors = {
  background: "#ffffff",
  background100: "#f8f8fa",
  background200: "#f1f2f3",
  background300: "#e3e5e8",
  mainTextColor: "#737b8c",
  boldTextColor: "#000000",
  white: "#ffffff",
  accentColor: "#ffcc00",
};

const lightColors = {
  background: "#ffffff",
  background100: "#f8f8fa",
  background200: "#f1f2f3",
  background300: "#e3e5e8",
  mainTextColor: "#737b8c",
  boldTextColor: "#000000",
  white: "#ffffff",
  accentColor: "#ffcc00",
};

const theme = true ? darkColors : lightColors;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route component={Routes} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
