import styled, { StyledProps } from "styled-components";
import React, { ChangeEvent } from "react";

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  color: ${({ theme }) => theme.mainTextColor};
  padding: 10px;
  outline: transparent;
  font-size: 1rem;
  font-family: ${(props) => props.theme.mainFont};
  transition: 0.3s;
  box-sizing: border-box;
  background: ${({ theme, disabled }) =>
    disabled ? theme.fadedBackgroundColor : "transparent"};

  &:disabled {
    color: ${({ theme }) => theme.gray};
  }

  &::placeholder {
    color: ${({ theme }) => theme.gray};
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  border: ${({ theme }) => {
    const base = "1px solid ";
    return base + theme.dark008;
  }};
  border-radius: 2px;
  align-items: center;
  position: relative;
  margin: 4px 0;
`;

const HorizontalLayout = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

// 0.875rem = 14px if root/html is 16px
const Label = styled.label<StyledProps<{ width?: string }>>`
  font-family: ${(props) => props.theme.mainFont};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.fadedTextColor};
  font-size: 0.875rem;
  position: relative;
  margin-bottom: 16px;
  width: ${({ width }) => width || "100%"};
`;

export interface InputFieldProps {
  label: string;
  type?: string;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  className?: string;
  width?: string;
}

const InputField = ({
  label,
  onChange,
  type = "text",
  className,
  width,
}: InputFieldProps) => {
  return (
    <Label className={className} width={width}>
      <HorizontalLayout>{label}</HorizontalLayout>
      <InputWrapper>
        <Input type={type} onChange={onChange} />
      </InputWrapper>
    </Label>
  );
};

export default InputField;
