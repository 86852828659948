import { convertMilliSecondsToReadableVersion } from "helpers";
import { useEffect, useState } from "react";
import styled, { StyledProps } from "styled-components";

const MainWrapper = styled.div`
  width: 100%;
  height: 180px;
  padding: 20px 20px 28px;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.background100};
  background-color: ${({ theme }) => theme.white};
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: #9f9caa;
`;

const Value = styled.div<StyledProps<any>>`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: ${({ small }) => (small ? "2rem" : "3.75rem")};
  color: #574f6b;
`;

export enum ValueType {
  single,
  time,
}

export interface InfoCardProps {
  title: string;
  value: number;
  valueType?: ValueType;
  trend?: string;
}

const InfoCard = ({ title, value, valueType, trend }: InfoCardProps) => {
  const [formattedValue, setFormattedValue] = useState<string>("");

  useEffect(() => {
    if (valueType && valueType === ValueType.time) {
      setFormattedValue(convertMilliSecondsToReadableVersion(value));
    } else {
      setFormattedValue(value.toString());
    }
  }, [value, valueType]);
  return (
    <MainWrapper>
      <Title>{title}</Title>
      <Value small={valueType === ValueType.time}>{formattedValue}</Value>
    </MainWrapper>
  );
};

export default InfoCard;
