// Styling
import styled from "styled-components";

import Background1 from "resources/login-3.jpg";
import { useEffect, useState } from "react";

const MainWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: fit-content;
  width: 250px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 32px;
`;
const Label = styled.div`
  color: white;
  margin-bottom: 8px;
`;

const Value = styled.div`
  font-size: 4rem;
  color: white;
  font-weight: 500;
`;

const FirstValue = styled(Value)``;

const BackgroundImage = styled.img`
  border-radius: 10px;
  position: absolute;
  height: 100%;
`;

const Overlay = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    145deg,
    rgba(15, 142, 194, 0.8),
    rgba(249, 239, 209, 0.8) 86%
  );
  z-index: 1;
`;

export interface TotalUnitsProps {
  totalVisits: number;
  totalSharecodes: number;
}

const TotalShowcases = ({ totalVisits, totalSharecodes }: TotalUnitsProps) => {
  const [averageVisits, setAverageVisits] = useState<number>(0);

  useEffect(() => {
    if (totalVisits && totalSharecodes) {
      setAverageVisits(Math.round((totalVisits / totalSharecodes) * 100) / 100);
    }
  }, [totalVisits, totalSharecodes]);
  return (
    <MainWrapper>
      <BackgroundImage src={Background1} />
      <Overlay>
        <Label>Total visits</Label>
        <FirstValue>{totalVisits}</FirstValue>
        <Label>Total sharecodes</Label>
        <Value>{totalSharecodes}</Value>
        <Label>Average visits per sharecode</Label>
        <Value>{averageVisits}</Value>
      </Overlay>
    </MainWrapper>
  );
};

export default TotalShowcases;
