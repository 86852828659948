import { QueryResult } from "../models/Node";
import { runQuery } from "../neo4jHelper";

export const Units = {
  getAllUnitStatusUpdatesSinceTimestamp: (timestamp: number) => {
    const query = `MATCH (u:User)-[:CREATED]->(a:Action {name:"unitStatusUpdate"})
    MATCH (a)-[:REFERS]->(unit:Unit)
    MATCH (unit)-[:BELONGS_TO]->(p:Project)-[:BELONGS_TO]->(v:Vault)
    WHERE a.createdAt >= ${timestamp}
    AND a.newValue = "SOLD"
    AND a.oldValue IN ["IN_OPTION", "AVAILABLE"]
    RETURN v.name, unit.localizedTitle, unit.title`;

    return runQuery(query).then((result: QueryResult) => {
      const parsed: Array<{
        vaultName: string;
        unitTitle: any;
        unitTitleFallback: string;
      }> = [];

      result.records.forEach((record) => {
        const vaultName = record._fields[0].toString();
        const unitTitle = record._fields[1]
          ? JSON.parse(record._fields[1].toString())
          : null;
        const unitTitleFallback = record._fields[2]?.toString() ?? "";

        parsed.push({
          vaultName,
          unitTitle,
          unitTitleFallback,
        });
      });

      console.log(parsed);
      return parsed;
    });
  },
};
