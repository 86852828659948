// Styling
import styled from "styled-components";

import Background1 from "resources/login-3.jpg";
import { Vault } from "helpers/neo4j/models/GVault";

const MainWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: fit-content;
  width: 250px;
  border-radius: 10px;
  overflow: hidden;
`;
const Label = styled.div`
  color: white;
  margin-bottom: 8px;
`;

const Value = styled.div`
  font-size: 4rem;
  color: white;
  font-weight: 500;
`;

const FirstValue = styled(Value)``;

const BackgroundImage = styled.img`
  border-radius: 10px;
  position: absolute;
  height: 100%;
`;

const Overlay = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    145deg,
    rgba(15, 142, 194, 0.8),
    rgba(249, 239, 209, 0.8) 86%
  );
  z-index: 1;
`;

export interface TotalCompaniesProps {
  allVaults: Array<Vault>;
  totalProjects: number;
  totalUnits: number;
  activeUnits: number;
}

const TotalCompanies = ({
  allVaults,
  totalProjects,
  totalUnits,
  activeUnits,
}: TotalCompaniesProps) => {
  return (
    <MainWrapper>
      <BackgroundImage src={Background1} />
      <Overlay>
        <Label>Total companies</Label>
        <FirstValue>{allVaults.length}</FirstValue>
        {/* <Label>Total Projects</Label>
        <Value>{totalProjects}</Value> */}
        <Label>Total Units</Label>
        <Value>{totalUnits}</Value>
        <Label>Active Units</Label>
        <Value>{activeUnits}</Value>
      </Overlay>
    </MainWrapper>
  );
};

export default TotalCompanies;
