// Helpers
import { Chart } from 'react-chartjs-2';
import { useDataState } from 'stores/DataStore';
import Background1 from 'resources/login-3.jpg';
//@ts-ignore
import sound1 from 'resources/looney.mp3';
//@ts-ignore
import sound2 from 'resources/yeah-boiii.mp3';
//@ts-ignore
import sound3 from 'resources/startup.mp3';
//@ts-ignore
import sound4 from 'resources/its-a-very-nice.mp3';

import { isMobile } from 'react-device-detect';

// Styling
import styled, { StyledProps } from 'styled-components';
import { buildLabelsAndTimestampsForLast30Days } from 'pages/dashboardPage/helpers/DashboardHelper';

import { Chart as ChartJS, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
import { dateToDDMMYY_HHMM } from 'helpers';
import {
  getAllMondayProjects,
  getMondayBoardItemFromProjectId,
  updateLeadAmountForProject
} from 'api';
ChartJS.register(...registerables);

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background100};
  box-sizing: border-box;
`;

const GraphContainer = styled.div`
  width: 96vw;
  height: 100%;
`;

const HalfGraphContainer = styled.div`
  width: 48vw;
  height: 100%;
`;

const HorizontalWrapper = styled.div<StyledProps<{ height?: number }>>`
  display: flex;
  flex-flow: row;
  margin: 16px;
  margin-bottom: 10px;
  box-sizing: border-box;
  height: ${({ height }) => (height ? height : '50')}%;
`;

const HalfScreen = styled.div`
  height: 45%;
`;

const MainWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: fit-content;
  max-height: 300px;
  min-width: 250px;
  max-width: 250px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 16px;
  cursor: pointer;
`;

const Label = styled.div`
  text-wrap: balance;
  font-size: 1rem;
`;

const SmallLabel = styled(Label)`
  height: 32px;
  margin-top: 15px;
  font-size: 0.75rem;
  max-width: 50px;
`;

const Value = styled.div`
  font-size: 4rem;
  font-weight: 500;
`;

const Divider = styled.div`
  width: 1px;
  height: 75px;
  background: gray;
  margin-right: 5px;
`;

const FirstValue = styled(Value)``;
const SmallValue = styled(Value)`
  font-size: 2rem;
`;

const BackgroundImage = styled.img`
  border-radius: 10px;
  position: absolute;
  height: 100%;
`;

const Overlay = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    145deg,
    rgba(249, 239, 209, 0.8),
    rgba(15, 142, 194, 0.8) 50%
  );
  z-index: 1;
`;

// const ProjectOverlay = styled(Overlay)`
//   background-image: linear-gradient(
//     145deg,
//     rgba(15, 142, 194, 0.8),
//     rgba(249, 239, 209, 0.8) 90%
//   );
// `;

// const Title = styled.div`
//   color: black;
//   font-size: 1.5rem;
//   margin-left: 16px;
//   margin-top: 16px;
// `;

// const ProjectsWrapper = styled(HorizontalWrapper)`
//   max-width: 100%;
//   flex-wrap: wrap;
// `;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const VerticalWrapperWithMargin = styled(VerticalWrapper)`
  margin-left: 10px;
  height: 150px;
`;

const VerticalWrapperFixedHeight = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 150px;
`;

const BackdropOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(46, 49, 56, 0.5);
  z-index: 3000;
`;

// const Center = styled.div`
//   position: fixed;
//   height: 100vh;
//   width: 100vw;
//   left: 0;
//   top: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 3000;
// `;

// const Modal = styled.div`
//   position: relative;
//   padding: 24px 32px 32px 32px;
//   padding-bottom: 12px;
//   background-color: white;
//   z-index: 2000;
//   box-sizing: border-box;
//   overflow: hidden;
//   overflow-y: auto;
//   border-radius: 8px;
// `;

const LeadOverviewList = styled.div<StyledProps<{ selected?: boolean }>>`
  display: flex;
  flex-flow: column;
  overflow: auto;
  padding-right: 20px;
  max-height: 200px;
  max-width: 500px;

  ${({ selected }) =>
    selected &&
    `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background:white;
    max-width: 100vw;
    max-height: 500px;
    padding: 25px;
    z-index: 4000;
`}
`;

const LeadOverviewCard = styled.div`
  padding: 8px;
  display: flex;
  flex-flow: row;
  border-bottom: 1px solid gray;
  margin-bottom: 4px;
`;

const LeadOverviewCardMobile = styled(LeadOverviewCard)`
  display: grid;
  grid-template-areas:
    'd p'
    'n n';
`;

const LeadCardDate = styled.div`
  grid-area: d;
`;

const LeadCardName = styled.div`
  grid-area: n;
`;

const LeadCardProject = styled.div`
  grid-area: p;
`;

const LeadCardItem = styled.div`
  margin-right: 20px;
  width: 200px;
`;

const LeadCardItemSmall = styled.div`
  margin-right: 20px;
  width: 75px;
`;

const LeadCardItemExtraSmall = styled.div`
  margin-right: 20px;
  width: 25px;
`;

const Pill = styled.div`
  color: black;
  margin-right: 10px;
`;

const SmallPill = styled(Pill)``;

const SmallPillMargin = styled(Pill)`
  margin-right: 30px;
`;

const SlopeIndicator = styled.div<StyledProps<{ increasing: boolean }>>`
  color: ${({ increasing }) => (increasing ? 'green' : 'red')};
  margin-left: 3px;
`;

const Trend = styled.div`
  display: flex;
  flex-flow: row;
  margin-right: 10px;
`;

const audio1 = new Audio(sound1);
const audio2 = new Audio(sound2);
const audio3 = new Audio(sound3);
const audio4 = new Audio(sound4);
const MarketplacePage = () => {
  const { DataState } = useDataState();
  const { marketplace, totalMarketplaceAccounts } = DataState;
  const {
    totalMarketplaceVisitsPerTimerange,
    marketplaceProjectVisits,
    uniqueVisitsPerProjectPerTimerange,
    totalMarketplaceVisitsInLast30Days,
    uniqueMarketplaceVisitsInLast30Days,
    totalMarketplaceVisits,
    totalUniqueMarketplaceVisits,
    totalLeadsGeneratedPerProject,
    totalVisitsPerProjectInLast30Days,
    uniqueVisitsPerProjectInLast30Days,
    totalHomepageVisitorsPerTimerange,
    totalProjectsVisitorsPerTimerange
  } = marketplace;

  //const [selectedProject, setSelectedProject] = useState<any>();

  const labels = buildLabelsAndTimestampsForLast30Days();
  labels.reverse();

  const [totalLeadsGenerated, setTotalLeadsGenerated] = useState<number>(0);
  const [selectedInfo, setSelectedInfo] = useState<any>();
  const [leadsSentToMonday, setLeadsSentToMonday] = useState<boolean>(false);

  // Lead data
  const [leadsToday, setLeadsToday] = useState<any>([]);
  const [leadsThisWeek, setleadsThisWeek] = useState<any>([]);
  const [leadsThisMonth, setLeadsThisMonth] = useState<any>([]);

  type alignment = 'end' | 'start' | 'center';
  const align: alignment = 'center';

  // Total MP visits per day
  let totalVisitsData: Array<number> = [];
  if (totalMarketplaceVisitsPerTimerange && labels) {
    totalVisitsData = labels.map((x) => {
      let countForDay = 0;

      totalMarketplaceVisitsPerTimerange.forEach((y: any) => {
        if (
          y.startTimestamp === x.startTimestamp &&
          y.endTimestamp === x.endTimestamp
        ) {
          countForDay += y.visits;
        }
      });

      return countForDay;
    });
  }

  // Unique MP visits per day
  // let uniqueVisitsData: Array<number> = [];
  // if (uniqueMarketplaceVisitsPerTimerange && labels) {
  //   uniqueVisitsData = labels.map((x) => {
  //     let countForDay = 0;

  //     uniqueMarketplaceVisitsPerTimerange.forEach((y: any) => {
  //       if (
  //         y.startTimestamp === x.startTimestamp &&
  //         y.endTimestamp === x.endTimestamp
  //       ) {
  //         countForDay += y.visits;
  //       }
  //     });

  //     return countForDay;
  //   });
  // }

  let formattedProjects: Array<any> = [];
  if (marketplaceProjectVisits) {
    marketplaceProjectVisits.forEach((x: any) => {
      const matchingProject = formattedProjects.find(
        (y) => y.projectTitle === x.projectTitle
      );
      const convertedData = {
        startTimestamp: x.startTimestamp,
        endTimestamp: x.endTimestamp,
        count: x.count
      };

      if (matchingProject) {
        matchingProject.data.push(convertedData);
      } else {
        formattedProjects.push({
          projectTitle: x.projectTitle,
          data: [convertedData]
        });
      }
    });
  }

  let totalHomepageVisitsData: Array<any> = [];
  if (totalHomepageVisitorsPerTimerange && labels) {
    totalHomepageVisitsData = labels.map((x) => {
      let countForDay = 0;

      totalHomepageVisitorsPerTimerange.forEach((y: any) => {
        if (
          y.startTimestamp === x.startTimestamp &&
          y.endTimestamp === x.endTimestamp
        ) {
          countForDay += y.visits;
        }
      });

      return countForDay;
    });
  }

  let totalProjectsVisitsData: Array<any> = [];
  if (totalProjectsVisitorsPerTimerange && labels) {
    totalProjectsVisitsData = labels.map((x) => {
      let countForDay = 0;

      totalProjectsVisitorsPerTimerange.forEach((y: any) => {
        if (
          y.startTimestamp === x.startTimestamp &&
          y.endTimestamp === x.endTimestamp
        ) {
          countForDay += y.visits;
        }
      });

      return countForDay;
    });
  }

  // useEffect(() => {
  //   if (unitsSold && unitsSold.length > 0) {
  //     unitsSold.forEach((unit: any) => {
  //       console.log("will send slack message");
  //       sendUnitSoldMessageToSlack(
  //         unit.vaultName,
  //         unit.unitTitle ? unit.unitTitle.en : unit.unitTitleFallback
  //       );
  //     });
  //   }
  // }, [unitsSold]);

  useEffect(() => {
    if (totalLeadsGeneratedPerProject) {
      let totalMarketplaceLeads = totalLeadsGeneratedPerProject.length;

      setTotalLeadsGenerated((current: number) => {
        if (current !== totalMarketplaceLeads && current !== 0) {
          const song = Math.floor(Math.random() * (4 - 1 + 1) + 1);

          switch (song) {
            default:
            case 1:
              audio1.play();
              break;
            case 2:
              audio2.play();
              break;
            case 3:
              audio3.play();
              break;
            case 4:
              audio4.play();
              break;
          }
        }
        return totalMarketplaceLeads;
      });
    }
  }, [totalLeadsGeneratedPerProject]);

  useEffect(() => {
    if (totalLeadsGeneratedPerProject) {
      const leadsDay: Array<any> = [];
      const leadsWeek: Array<any> = [];
      const leadsMonth: Array<any> = [];

      totalLeadsGeneratedPerProject
        .filter((a: any) => {
          const startOfDay = new Date();
          startOfDay.setUTCHours(0, 0, 0, 0);

          return a.lead.createdAt >= startOfDay.getTime();
        })
        .forEach((lead: any) => {
          const matchedP = leadsDay.find((x: any) => {
            return x.title === lead.projectTitle;
          });

          if (matchedP) {
            matchedP.count += 1;
          } else {
            leadsDay.push({
              title: lead.projectTitle,
              count: 1
            });
          }
        });

      const startOfWeek = new Date();
      const day = startOfWeek.getDay();
      const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1);
      startOfWeek.setDate(diff);
      startOfWeek.setUTCHours(-1);
      startOfWeek.setUTCMinutes(0);

      const startOfMonth = new Date();
      startOfMonth.setDate(1);
      startOfMonth.setUTCHours(-1);
      startOfMonth.setUTCMinutes(0);

      totalLeadsGeneratedPerProject
        .filter((a: any) => {
          const timestamp = startOfWeek.getTime();

          return a.lead.createdAt >= timestamp;
        })
        .forEach((lead: any) => {
          const matchedP = leadsWeek.find((x: any) => {
            return x.title === lead.projectTitle;
          });

          if (matchedP) {
            matchedP.count += 1;
          } else {
            leadsWeek.push({
              title: lead.projectTitle,
              count: 1
            });
          }
        });

      totalLeadsGeneratedPerProject
        .filter((a: any) => {
          const timestamp = startOfMonth.getTime();
          return a.lead.createdAt >= timestamp;
        })
        .forEach((lead: any) => {
          const matchedP = leadsMonth.find((x: any) => {
            return x.title === lead.projectTitle;
          });

          if (matchedP) {
            matchedP.count += 1;
          } else {
            leadsMonth.push({
              title: lead.projectTitle,
              objectId: lead.projectObjectId,
              count: 1
            });
          }
        });

      setLeadsToday(leadsDay);
      setleadsThisWeek(leadsWeek);
      setLeadsThisMonth(leadsMonth);
    }
  }, [totalLeadsGeneratedPerProject]);

  let heighestLeadCount = 0;
  let parsedLeadData = labels.map((x: any) => {
    let leadsCreatedForDate = 0;

    totalLeadsGeneratedPerProject.forEach((y: any) => {
      if (
        y.lead.createdAt >= x.startTimestamp &&
        y.lead.createdAt <= x.endTimestamp
      ) {
        leadsCreatedForDate++;
      }
    });

    if (leadsCreatedForDate > heighestLeadCount) {
      heighestLeadCount = leadsCreatedForDate;
    }
    return leadsCreatedForDate;
  });

  const maxYValue = Math.max(...totalVisitsData) + 50;
  const leadOptions = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: align,
        offset: 10,
        opacity: 1,
        color: 'black'
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      y: {
        min: 0,
        max: heighestLeadCount + 3,
        grid: {
          z: 0,
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        position: 'left'
      },
      y2: {
        min: 0,
        max: 1,
        grid: {
          z: 0,
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        position: 'right',
        display: false
      },
      x: {
        grid: {
          z: 1
        }
      }
    }
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: align,
        offset: 10,
        opacity: 1,
        color: 'black'
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      y: {
        display: true,
        min: 0,
        max: maxYValue,
        grid: {
          z: 1
        }
      },
      y2: {
        min: 0,
        max: 1,
        grid: {
          z: 0,
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        position: 'right',
        display: false
      },
      x: {
        grid: {
          z: 1
        }
      }
    }
  };

  let homepageOptions = structuredClone(options);
  let projectspageOptions = structuredClone(options);
  homepageOptions.scales.y.max = Math.max(...totalHomepageVisitsData) + 50;
  projectspageOptions.scales.y.max = Math.max(...totalProjectsVisitsData) + 50;

  const calculateTrendline = (data: any) => {
    const clonedData = structuredClone(data);
    clonedData.pop();
    let sumX = 0,
      sumY = 0,
      sumXY = 0,
      sumX2 = 0;
    const N = clonedData.length;

    clonedData.forEach((value: number, index: number) => {
      sumX += index;
      sumY += value;
      sumXY += index * value;
      sumX2 += index * index;
    });

    const slope = (N * sumXY - sumX * sumY) / (N * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / N;

    const newData = clonedData.map((value: any, index: number) => {
      return { x: index, y: slope * index + intercept };
    });
    newData.push(newData[newData.length - 1]);

    return {
      data: newData,
      slope
    };
  };

  const leadTrendLine = calculateTrendline(parsedLeadData);
  const leadTrendLineData = leadTrendLine.data;
  const leadSlope = leadTrendLine.slope;
  const leadData = {
    labels: labels.map((x) => {
      return x.label;
    }),
    datasets: [
      {
        type: 'line' as const,
        fill: false,
        data: leadTrendLineData,
        borderColor: 'rgba(255, 0, 0,1)',
        backgroundColor: 'rgba(255, 0, 0,1)',
        z: 2,
        pointRadius: 0,
        datalabels: {
          display: false // Show data labels for the first dataset
        }
      },
      {
        type: 'line' as const,
        fill: true,
        data: parsedLeadData,
        barPercentage: 1,
        label: 'Leads generated',
        borderColor: 'rgb(255, 0,0,0.25)',
        backgroundColor: 'rgb(255, 0, 0,0.25)',
        z: 3,
        pointRadius: 0
      },
      {
        type: 'bar' as const,
        fill: false,
        label: 'Weekend',
        data: labels?.map((x: any) => {
          if (x.isWeekend) return 1;
          return '';
        }),
        barPercentage: 1,
        color: 'rgb(231, 232, 233,1)',
        borderColor: 'rgb(231, 232, 233,1)',
        backgroundColor: 'rgb(231, 232, 233,1)',
        yAxisID: 'y2',
        datalabels: {
          display: false // Show data labels for the first dataset
        }
      }
    ]
  };

  const totalTrendLine = calculateTrendline(totalVisitsData);
  const totalTrendLineData = totalTrendLine.data;
  const totalSlope = totalTrendLine.slope;
  const visitData = {
    labels: labels.map((x) => {
      return x.label;
    }),
    datasets: [
      {
        type: 'line' as const,
        fill: false,
        data: totalTrendLineData,
        borderColor: 'rgb(21, 171, 144,1)',
        backgroundColor: 'rgb(21, 171, 144,1)',
        z: 2,
        pointRadius: 0,
        datalabels: {
          display: false // Show data labels for the first dataset
        }
      },
      {
        type: 'line' as const,
        fill: true,
        label: 'Total Visitors',
        data: totalVisitsData,
        borderColor: 'rgb(21, 171, 144,0.25)',
        backgroundColor: 'rgb(21, 171, 144,0.25)',
        pointRadius: 0
      },
      {
        type: 'bar' as const,
        fill: false,
        label: 'Weekend',
        data: labels?.map((x: any) => {
          if (x.isWeekend) return 1;
          return '';
        }),
        barPercentage: 1,
        color: 'rgb(231, 232, 233,1)',
        borderColor: 'rgb(231, 232, 233,1)',
        backgroundColor: 'rgb(231, 232, 233,1)',
        yAxisID: 'y2',
        datalabels: {
          display: false // Show data labels for the first dataset
        }
      }
    ]
  };

  const homepageTrendLine = calculateTrendline(totalHomepageVisitsData);
  const homepageTrendLineData = homepageTrendLine.data;
  const homepageSlope = homepageTrendLine.slope;

  const projectspageT = calculateTrendline(totalProjectsVisitsData);
  const projectspageS = projectspageT.slope;

  const homePageData = {
    labels: labels.map((x) => {
      return x.label;
    }),
    datasets: [
      {
        type: 'line' as const,
        fill: false,
        data: homepageTrendLineData,
        borderColor: 'rgba(15, 142, 194,1)',
        backgroundColor: 'rgba(15, 142, 194,1)',
        z: 2,
        pointRadius: 0,
        datalabels: {
          display: false // Show data labels for the first dataset
        }
      },
      {
        type: 'line' as const,
        fill: true,
        label: 'Homepage Visitors',
        data: totalHomepageVisitsData,
        borderColor: 'rgba(15, 142, 194,0.25)',
        backgroundColor: 'rgba(15, 142, 194,0.25)',
        pointRadius: 0
      },
      {
        type: 'bar' as const,
        fill: false,
        label: 'Weekend',
        data: labels?.map((x: any) => {
          if (x.isWeekend) return 1;
          return '';
        }),
        barPercentage: 1,
        color: 'rgb(231, 232, 233,1)',
        borderColor: 'rgb(231, 232, 233,1)',
        backgroundColor: 'rgb(231, 232, 233,1)',
        yAxisID: 'y2',
        datalabels: {
          display: false // Show data labels for the first dataset
        }
      }
    ]
  };

  const projectsPageData = {
    labels: labels.map((x) => {
      return x.label;
    }),
    datasets: [
      {
        type: 'line' as const,
        fill: false,
        data: projectspageT.data,
        borderColor: 'rgb(21, 171, 144,1)',
        backgroundColor: 'rgb(21, 171, 144,1)',
        z: 2,
        pointRadius: 0,
        datalabels: {
          display: false // Show data labels for the first dataset
        }
      },
      {
        type: 'line' as const,
        fill: true,
        label: 'Projects Visitors',
        data: totalProjectsVisitsData,
        borderColor: 'rgb(21, 171, 144,0.25)',
        backgroundColor: 'rgb(21, 171, 144,0.25)',
        pointRadius: 0
      },
      {
        type: 'bar' as const,
        fill: false,
        label: 'Weekend',
        data: labels?.map((x: any) => {
          if (x.isWeekend) return 1;
          return '';
        }),
        barPercentage: 1,
        color: 'rgb(231, 232, 233,1)',
        borderColor: 'rgb(231, 232, 233,1)',
        backgroundColor: 'rgb(231, 232, 233,1)',
        yAxisID: 'y2',
        datalabels: {
          display: false // Show data labels for the first dataset
        }
      }
    ]
  };

  formattedProjects = formattedProjects.map((y: any) => {
    let totalVisits: Array<number> = [];
    if (y.data && labels) {
      totalVisits = labels.map((x) => {
        let countForDay = 0;

        y.data.forEach((y: any) => {
          if (
            y.startTimestamp === x.startTimestamp &&
            y.endTimestamp === x.endTimestamp
          ) {
            countForDay += y.count;
          }
        });

        return countForDay;
      });
    }

    let uniqueVisits: Array<number> = [];
    if (uniqueVisitsPerProjectPerTimerange && labels) {
      uniqueVisits = labels.map((x) => {
        let countForDay = 0;

        uniqueVisitsPerProjectPerTimerange.forEach((z: any) => {
          if (
            z.startTimestamp === x.startTimestamp &&
            z.endTimestamp === x.endTimestamp &&
            y.projectTitle === z.projectTitle
          ) {
            countForDay += z.count;
          }
        });

        return countForDay;
      });
    }

    let totalLeads = totalLeadsGeneratedPerProject.filter((x: any) => {
      return x.projectTitle === y.projectTitle;
    }).length;

    let totalVisitsLast30Days =
      totalVisitsPerProjectInLast30Days.filter((x: any) => {
        return x.projectTitle === y.projectTitle;
      })[0]?.count ?? 0;

    let uniqueVisitsLast30Days =
      uniqueVisitsPerProjectInLast30Days.filter((x: any) => {
        return x.projectTitle === y.projectTitle;
      })[0]?.count ?? 0;

    const projectOptions = structuredClone(options);
    projectOptions.scales.y.max = Math.max(...totalVisits) + 50;

    let heighestLeadPCount = 0;
    let parsedLeadPData = labels.map((x: any) => {
      let leadsCreatedForDate = 0;

      totalLeadsGeneratedPerProject.forEach((z: any) => {
        if (
          z.lead.createdAt >= x.startTimestamp &&
          z.lead.createdAt <= x.endTimestamp &&
          z.projectTitle === y.projectTitle
        ) {
          leadsCreatedForDate++;
        }
      });

      if (leadsCreatedForDate > heighestLeadPCount) {
        heighestLeadPCount = leadsCreatedForDate;
      }
      return leadsCreatedForDate;
    });

    const projectData = {
      labels: labels.map((x) => {
        return x.label;
      }),
      datasets: [
        {
          type: 'line' as const,
          fill: true,
          label: 'Unique visits',
          data: uniqueVisits,
          borderColor: 'rgba(15, 142, 194,0.5)',
          backgroundColor: 'rgba(15, 142, 194,0.5)',
          z: 2
        },
        {
          type: 'line' as const,
          fill: true,
          label: 'Total visits',
          data: totalVisits,
          borderColor: 'rgb(21, 171, 144,0.5)',
          backgroundColor: 'rgb(21, 171, 144,0.5)',
          z: 1
        },
        {
          type: 'line' as const,
          fill: false,
          data: parsedLeadPData,
          barPercentage: 1,
          label: 'Leads generated',
          borderColor: 'rgb(255, 0,0,1)',
          backgroundColor: 'rgb(255, 0, 0,1)',
          yAxisID: 'y1',
          z: 3
        },
        {
          type: 'bar' as const,
          fill: false,
          label: 'Weekend',
          data: labels?.map((x: any) => {
            if (x.isWeekend) return 1;
            return '';
          }),
          barPercentage: 1,
          borderColor: 'rgb(231, 232, 233,1)',
          backgroundColor: 'rgb(231, 232, 233,1)',
          yAxisID: 'y2'
        }
      ]
    };

    return {
      ...y,
      totalVisitsLast30Days,
      uniqueVisitsLast30Days,
      totalLeads,
      projectData,
      projectOptions
    };
  });

  formattedProjects = formattedProjects.sort((a: any, b: any) => {
    if (a.totalVisitsLast30Days > b.totalVisitsLast30Days) return -1;
    return 1;
  });

  let leadsThisMonthCount = 0;
  let leadsThisWeekCount = 0;
  let leadsTodayCount = 0;

  if (leadsThisMonth?.length > 0) {
    leadsThisMonthCount = leadsThisMonth.reduce((acc: any, curr: any) => {
      return acc.count ? acc.count + curr.count : acc + curr.count;
    }, 0);
  }
  if (leadsThisWeek?.length > 0) {
    leadsThisWeekCount = leadsThisWeek.reduce((acc: any, curr: any) => {
      return acc.count ? acc.count + curr.count : acc + curr.count;
    }, 0);
  }
  if (leadsToday?.length > 0) {
    if (leadsToday.length === 1) {
      leadsTodayCount = leadsToday[0].count;
    } else {
      leadsTodayCount = leadsToday.reduce((acc: any, curr: any) => {
        return acc.count ? acc.count + curr.count : acc + curr.count;
      }, 0);
    }
  }

  useEffect(() => {
    if (leadsThisMonth?.length > 0 && !leadsSentToMonday) {
      setLeadsSentToMonday(true);

      for (let index = 0; index < leadsThisMonth.length; index++) {
        setTimeout(() => {
          getMondayBoardItemFromProjectId(leadsThisMonth[index].objectId).then(
            (result) => {
              const { data } = result;
              const { items_page_by_column_values } = data?.data;
              const { items } = items_page_by_column_values;

              const itemInMonday = items[0];

              updateLeadAmountForProject(
                itemInMonday.id,
                leadsThisMonth[index].count
              ).then(() => {
                console.log(`updated ${leadsThisMonth[index].title} in Monday`);
              });
            }
          );
        }, index * 1000);
      }

      // Reset others to 0
      getAllMondayProjects().then((result) => {
        if (result?.data?.data?.boards[0]?.items_page?.items) {
          const items = result.data.data.boards[0].items_page.items;
          const formatted = items.map((x: any) => {
            return {
              id: x.id,
              name: x.name,
              currentCount: Number(
                x.column_values.find((y: any) => y.id === 'nummers9')?.text
              ),
              projectId: x.column_values.find((y: any) => y.id === 'tekst')
                ?.text
            };
          });

          // Get all the projects not yet in this months leads
          const diff = formatted.filter((x: any) => {
            const foundMatch = leadsThisMonth.find((y: any) => {
              return y.objectId === x.projectId;
            });

            if (foundMatch) {
              return false;
            }

            return true;
          });

          diff.forEach((d: any) => {
            if (d.currentCount !== 0) {
              updateLeadAmountForProject(d.id, 0).then((r: any) => {
                console.log(`resetted ${d.name}`);
              });
            }
          });
        }
      });

      setTimeout(() => {
        setLeadsSentToMonday(false);
      }, 600 * 1000);
    }
  }, [leadsThisMonth, leadsSentToMonday]);

  const desktopContent = (
    <>
      <HalfScreen>
        <HorizontalWrapper height={20}>
          <Pill>
            <Label>Total visits</Label>
            <FirstValue>{`${(totalMarketplaceVisits / 1000).toFixed(
              1
            )}K`}</FirstValue>
          </Pill>
          <SmallPillMargin>
            <SmallLabel>last 30 days</SmallLabel>
            <SmallValue>{`${(totalMarketplaceVisitsInLast30Days / 1000).toFixed(
              1
            )}K`}</SmallValue>
          </SmallPillMargin>
          <Divider />
          <Pill>
            <Label>Unique visits</Label>
            <FirstValue>{`${(totalUniqueMarketplaceVisits / 1000).toFixed(
              1
            )}K`}</FirstValue>
          </Pill>
          <SmallPillMargin>
            <SmallLabel>last 30 days</SmallLabel>
            <SmallValue>{`${(
              uniqueMarketplaceVisitsInLast30Days / 1000
            ).toFixed(1)}K`}</SmallValue>
          </SmallPillMargin>
          <VerticalWrapperFixedHeight>
            <LeadOverviewList
              onClick={() => {
                setSelectedInfo('totalLeads');
              }}
              selected={selectedInfo === 'totalLeads'}
            >
              {totalLeadsGeneratedPerProject
                .sort((a: any, b: any) => {
                  if (a.lead.createdAt > b.lead.createdAt) return -1;
                  return 1;
                })
                .map((x: any) => {
                  return (
                    <LeadOverviewCard>
                      <LeadCardItem>
                        {dateToDDMMYY_HHMM(new Date(x.lead.createdAt))}
                      </LeadCardItem>
                      <LeadCardItem> {x.lead.name}</LeadCardItem>
                      <LeadCardItem> {x.projectTitle}</LeadCardItem>
                      <LeadCardItem> {x.vault.name}</LeadCardItem>
                    </LeadOverviewCard>
                  );
                })}
            </LeadOverviewList>
          </VerticalWrapperFixedHeight>
          <VerticalWrapperWithMargin>
            <b>{`Leads today (${
              leadsToday?.length > 0 &&
              leadsToday.reduce((acc: any, curr: any) => {
                return acc.count ? acc.count + curr.count : acc + curr.count;
              }, 0)
            })`}</b>
            <LeadOverviewList
              onClick={() => {
                setSelectedInfo('todayLeads');
              }}
              selected={selectedInfo === 'todayLeads'}
            >
              {leadsToday
                .sort((a: any, b: any) => {
                  if (a.count > b.count) return -1;
                  return 1;
                })
                .map((x: any) => {
                  return (
                    <LeadOverviewCard>
                      <LeadCardItemSmall> {x.title}</LeadCardItemSmall>
                      <LeadCardItemExtraSmall>
                        {' '}
                        {x.count}
                      </LeadCardItemExtraSmall>
                    </LeadOverviewCard>
                  );
                })}
            </LeadOverviewList>
          </VerticalWrapperWithMargin>
          <VerticalWrapperWithMargin>
            <b>{`Leads this week (${
              leadsThisWeek?.length > 0 &&
              leadsThisWeek.reduce((acc: any, curr: any) => {
                return acc.count ? acc.count + curr.count : acc + curr.count;
              }, 0)
            })`}</b>
            <LeadOverviewList
              onClick={() => {
                setSelectedInfo('weekLeads');
              }}
              selected={selectedInfo === 'weekLeads'}
            >
              {leadsThisWeek
                .sort((a: any, b: any) => {
                  if (a.count > b.count) return -1;
                  return 1;
                })
                .map((x: any) => {
                  return (
                    <LeadOverviewCard>
                      <LeadCardItemSmall> {x.title}</LeadCardItemSmall>
                      <LeadCardItemExtraSmall>
                        {' '}
                        {x.count}
                      </LeadCardItemExtraSmall>
                    </LeadOverviewCard>
                  );
                })}
            </LeadOverviewList>
          </VerticalWrapperWithMargin>
          <VerticalWrapperWithMargin>
            <b>{`Leads this month (${
              leadsThisMonth?.length > 0 &&
              leadsThisMonth.reduce((acc: any, curr: any) => {
                return acc.count ? acc.count + curr.count : acc + curr.count;
              }, 0)
            })`}</b>
            <LeadOverviewList
              onClick={() => {
                setSelectedInfo('monthLeads');
              }}
              selected={selectedInfo === 'monthLeads'}
            >
              {leadsThisMonth
                .sort((a: any, b: any) => {
                  if (a.count > b.count) return -1;
                  return 1;
                })
                .map((x: any) => {
                  return (
                    <LeadOverviewCard>
                      <LeadCardItemSmall> {x.title}</LeadCardItemSmall>
                      <LeadCardItemExtraSmall>
                        {' '}
                        {x.count}
                      </LeadCardItemExtraSmall>
                    </LeadOverviewCard>
                  );
                })}
            </LeadOverviewList>
          </VerticalWrapperWithMargin>
        </HorizontalWrapper>
        <HorizontalWrapper height={20}>
          <Pill>
            <Label>Leads generated</Label>
            <FirstValue>{totalLeadsGenerated}</FirstValue>
          </Pill>
          <SmallPill>
            <SmallLabel>This Month</SmallLabel>
            <SmallValue>{leadsThisMonthCount}</SmallValue>
          </SmallPill>
          <SmallPill>
            <SmallLabel>This Week</SmallLabel>
            <SmallValue>{leadsThisWeekCount}</SmallValue>
          </SmallPill>
          <SmallPillMargin>
            <SmallLabel>Today</SmallLabel>
            <SmallValue>{leadsTodayCount}</SmallValue>
          </SmallPillMargin>
          <SmallPillMargin>
            <SmallLabel>Accounts</SmallLabel>
            <SmallValue>{totalMarketplaceAccounts}</SmallValue>
          </SmallPillMargin>
        </HorizontalWrapper>
        <HorizontalWrapper height={45}>
          <VerticalWrapper>
            <Trend>
              {`Leads Trend: `}
              <SlopeIndicator increasing={leadSlope > 0}>
                {` ${leadSlope.toFixed(2)}%`}
              </SlopeIndicator>
            </Trend>
            <GraphContainer>
              {/* @ts-ignore */}
              <Chart type="line" options={leadOptions} data={leadData} />
            </GraphContainer>
          </VerticalWrapper>
        </HorizontalWrapper>
      </HalfScreen>
      <HalfScreen>
        <HorizontalWrapper height={52}>
          <VerticalWrapper>
            <Trend>
              {`Total visits Trend: `}
              <SlopeIndicator increasing={totalSlope > 0}>
                {` ${totalSlope.toFixed(2)}%`}
              </SlopeIndicator>
            </Trend>
            <GraphContainer>
              {/* @ts-ignore */}
              <Chart type="line" options={options} data={visitData} />
            </GraphContainer>
          </VerticalWrapper>
        </HorizontalWrapper>
        <HorizontalWrapper height={52}>
          <VerticalWrapper>
            <Trend>
              {`Homepage Trend: `}
              <SlopeIndicator increasing={homepageSlope > 0}>
                {` ${homepageSlope.toFixed(2)}%`}
              </SlopeIndicator>
            </Trend>
            <HalfGraphContainer>
              {/* @ts-ignore */}
              <Chart
                type="line"
                //@ts-ignore
                options={homepageOptions}
                data={homePageData}
              />
            </HalfGraphContainer>
          </VerticalWrapper>
          <VerticalWrapper>
            <Trend>
              {` Projectspage Trend: `}
              <SlopeIndicator increasing={projectspageS > 0}>
                {` ${projectspageS?.toFixed(2)}%`}
              </SlopeIndicator>
            </Trend>
            <HalfGraphContainer>
              {/* @ts-ignore */}
              <Chart
                type="line"
                //@ts-ignore
                options={projectspageOptions}
                data={projectsPageData}
              />
            </HalfGraphContainer>
          </VerticalWrapper>
        </HorizontalWrapper>
      </HalfScreen>
      {selectedInfo && (
        <BackdropOverlay
          onClick={() => {
            setSelectedInfo('');
          }}
        />
      )}
    </>
  );
  return (
    <PageWrapper>
      {isMobile ? (
        <VerticalWrapper>
          <hr />
          <MainWrapper>
            <BackgroundImage src={Background1} />
            <Overlay>
              <Label>Total marketplace visits</Label>
              <FirstValue>{totalMarketplaceVisits}</FirstValue>
              <Label>Unique marketplace visits</Label>
              <FirstValue>{totalUniqueMarketplaceVisits}</FirstValue>
            </Overlay>
          </MainWrapper>
          <hr />
          <MainWrapper>
            <BackgroundImage src={Background1} />
            <Overlay>
              <Label>Total marketplace visits (last 30 days)</Label>
              <FirstValue>{totalMarketplaceVisitsInLast30Days}</FirstValue>
              <Label>Unique marketplace visits (last 30 days)</Label>
              <FirstValue>{uniqueMarketplaceVisitsInLast30Days}</FirstValue>
              <Label>Leads generated (Total)</Label>
              <FirstValue>{totalLeadsGenerated}</FirstValue>
            </Overlay>
          </MainWrapper>
          <hr />
          <VerticalWrapperWithMargin>
            <b>Leads Created</b>
            <LeadOverviewList>
              {totalLeadsGeneratedPerProject
                .sort((a: any, b: any) => {
                  if (a.lead.createdAt > b.lead.createdAt) return -1;
                  return 1;
                })
                .map((x: any) => {
                  return (
                    <LeadOverviewCardMobile>
                      <LeadCardDate>
                        {dateToDDMMYY_HHMM(new Date(x.lead.createdAt))}
                      </LeadCardDate>
                      <LeadCardName> {x.lead.name}</LeadCardName>
                      <LeadCardProject> {x.projectTitle}</LeadCardProject>
                    </LeadOverviewCardMobile>
                  );
                })}
            </LeadOverviewList>
            <hr />
            <b>{`Leads today (${
              leadsToday?.length > 0 &&
              leadsToday.reduce((acc: any, curr: any) => {
                return acc.count ? acc.count + curr.count : acc + curr.count;
              }, 0)
            })`}</b>
            <LeadOverviewList>
              {leadsToday
                .sort((a: any, b: any) => {
                  if (a.count > b.count) return -1;
                  return 1;
                })
                .map((x: any) => {
                  return (
                    <LeadOverviewCard>
                      <LeadCardItemSmall> {x.title}</LeadCardItemSmall>
                      <LeadCardItemExtraSmall>{x.count}</LeadCardItemExtraSmall>
                    </LeadOverviewCard>
                  );
                })}
            </LeadOverviewList>
          </VerticalWrapperWithMargin>
          <hr />
          <VerticalWrapperWithMargin>
            <b>{`Leads this week (${
              leadsThisWeek?.length > 0 &&
              leadsThisWeek.reduce((acc: any, curr: any) => {
                return acc.count ? acc.count + curr.count : acc + curr.count;
              }, 0)
            })`}</b>
            <LeadOverviewList>
              {leadsThisWeek
                .sort((a: any, b: any) => {
                  if (a.count > b.count) return -1;
                  return 1;
                })
                .map((x: any) => {
                  return (
                    <LeadOverviewCard>
                      <LeadCardItemSmall> {x.title}</LeadCardItemSmall>
                      <LeadCardItemExtraSmall>{x.count}</LeadCardItemExtraSmall>
                    </LeadOverviewCard>
                  );
                })}
            </LeadOverviewList>
          </VerticalWrapperWithMargin>
          <hr />
          <VerticalWrapperWithMargin>
            <b>{`Leads this month (${
              leadsThisMonth?.length > 0 &&
              leadsThisMonth.reduce((acc: any, curr: any) => {
                return acc.count ? acc.count + curr.count : acc + curr.count;
              }, 0)
            })`}</b>
            <LeadOverviewList>
              {leadsThisMonth
                .sort((a: any, b: any) => {
                  if (a.count > b.count) return -1;
                  return 1;
                })
                .map((x: any) => {
                  return (
                    <LeadOverviewCard>
                      <LeadCardItemSmall> {x.title}</LeadCardItemSmall>
                      <LeadCardItemExtraSmall>{x.count}</LeadCardItemExtraSmall>
                    </LeadOverviewCard>
                  );
                })}
            </LeadOverviewList>
          </VerticalWrapperWithMargin>
        </VerticalWrapper>
      ) : (
        desktopContent
      )}
    </PageWrapper>
  );
};

export default MarketplacePage;
